import { Grid, styled, Theme, Tooltip } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createMixins';
import { COLORS } from './colors';
import { StyledBodyText } from './Text';
import { SxProps } from '@mui/material';
import { Help } from '@mui/icons-material';
import { Input, inputClasses } from '@mui/base';

export const commonInputStyled = (theme: Theme, variant: InputVariant): CSSProperties => {
  const getFontSize = () => {
    switch (variant) {
      default:
      case 'outlined':
        return 16;
    }
  };
  const getFontWeight = () => {
    switch (variant) {
      default:
      case 'outlined':
        return 400;
    }
  };

  return {
    fontFamily: 'Nunito',
    fontWeight: getFontWeight(),
    fontSize: getFontSize(),
    color: COLORS.white[100],
  };
};

type InputVariant = 'outlined';

interface StyledInputProps {
  variant?: InputVariant;
  width?: number;
  height?: number;
}
export const StyledInput = styled(Input, {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'width' && prop !== 'height' && prop !== 'sx',
})<StyledInputProps>(({ variant = 'outlined', width, height, theme, multiline }) => ({
  [`& .${inputClasses.input}`]: {
    ...commonInputStyled(theme, variant),
    backgroundColor: 'transparent',
    width: width ? width : '100%',
    height: height ? height : '49px',
    boxSizing: 'border-box',
    border: `2px solid ${COLORS.black[30]}`,
    borderRadius: '7px',
    outline: 'none',
    '&:hover': {
      cursor: 'pointer',
      borderColor: COLORS.white[80],
    },
    maxWidth: '100%',
    padding: theme.spacing(),
    ...(multiline && {
      maxHeight: '400px',
      minWidth: '100%',
      height: height ? height : '173px',
    }),
  },
  [`&.${inputClasses.focused}`]: {
    [`& .${inputClasses.input}:read-write`]: {
      border: `2px solid ${COLORS.white[80]}`,
    },
  },
  [`&.${inputClasses.error} .${inputClasses.input}`]: {
    border: `2px solid ${COLORS.alert[100]}`,
  },
  [`& input:read-only`]: {
    background: COLORS.black[70],
    border: 0,
    color: COLORS.white[20],
    '&:hover': {
      cursor: 'default',
    },
  },
  [`&.${inputClasses.disabled} .${inputClasses.input}`]: {
    background: COLORS.black[70],
    border: 0,
    color: COLORS.white[20],
    '&:hover': {
      cursor: 'default',
    },
  },
}));

interface DefaultInputProps {
  value?: string;
  header?: string;
  headerTooltip?: string;
  placeholder?: string;
  error?: boolean;
  errorText?: string;
  onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  disabled?: boolean;
  readOnly?: boolean;
  sx?: SxProps<Theme>;
  adormentIcon?: React.ReactNode;
  adormentText?: {
    text: string;
    width: number;
  };
}

const BASE_ADORMENT_WIDTH = 16;

const DEFAULT_ADORMENT_ICON_WIDTH = 24;

export const DefaultInput: React.FC<DefaultInputProps> = ({
  value,
  header,
  placeholder,
  error = false,
  onChange,
  onBlur,
  errorText,
  disabled = false,
  sx,
  readOnly,
  adormentIcon,
  adormentText,
  headerTooltip,
}) => {
  const isAdorned = () => {
    return !!adormentIcon || !!adormentText;
  };
  const getStartAdorment = () => {
    if (isAdorned()) {
      return (
        <div
          style={{
            display: 'inline-flex',
            height: '100%',
            left: 16,
            alignItems: 'center',
            position: 'absolute',
            pointerEvents: 'none',
          }}
        >
          {adormentIcon}
          {!!adormentText && (
            <StyledBodyText variant="body" sx={{ m: 0, ml: 1 }} color="white20">
              {adormentText.text}
            </StyledBodyText>
          )}
        </div>
      );
    }
    return undefined;
  };

  const getAdornedPadding = () => {
    if (isAdorned()) {
      let width = BASE_ADORMENT_WIDTH;
      if (!!adormentIcon) {
        width = width + DEFAULT_ADORMENT_ICON_WIDTH;
      }
      if (!!adormentText) {
        width = width + adormentText.width;
      }
      return `${width + 8}px !important`;
    }
    return undefined;
  };

  return (
    <div style={{ position: 'relative' }}>
      {header && (
        <Grid container>
          <StyledBodyText variant="h3" sx={{ mt: 0.5, mb: 1, mr: 0.5 }}>
            {header}
          </StyledBodyText>
          {headerTooltip && (
            <Tooltip title={headerTooltip}>
              <Help sx={{ width: 20 }} />
            </Tooltip>
          )}
        </Grid>
      )}
      <StyledInput
        value={value}
        placeholder={placeholder}
        error={error}
        disabled={disabled}
        onChange={(ev: any) => {
          if (onChange) {
            onChange(ev);
          }
        }}
        onBlur={() => {
          if (onBlur) {
            onBlur();
          }
        }}
        readOnly={readOnly}
        startAdornment={getStartAdorment()}
        sx={{ '& > input': { pl: getAdornedPadding() }, position: 'relative', ...sx }}
      />
      {!!errorText && (
        <StyledBodyText variant="bodyLower2" color="error" sx={{ m: 0, height: 12 }}>
          {!!error && errorText}
        </StyledBodyText>
      )}
    </div>
  );
};
