import { styled, Theme } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createMixins';
import { COLORS } from './colors';

export const commonTextStyled = (variant: TextVariant, color: TextColor): CSSProperties => {
  const getColor = () => {
    switch (color) {
      case 'error':
        return COLORS.alert[100];
      case 'warning':
        return COLORS.warning[100];
      case 'black10':
        return COLORS.black[10];
      case 'black80':
        return COLORS.black[80];
      case 'white10':
        return COLORS.white[10];
      case 'white20':
        return COLORS.white[20];
      case 'white30':
        return COLORS.white[30];
      case 'white50':
        return COLORS.white[50];
      case 'white60':
        return COLORS.white[60];
      case 'white90':
        return COLORS.white[90];
      case 'white100':
        return COLORS.white[100];
      case 'alert60':
        return COLORS.alert[60];
      case 'success':
        return COLORS.success[100];
      default:
      case 'white80':
        return COLORS.white[80];
    }
  };
  const getFontSize = () => {
    switch (variant) {
      case 'h1':
        return 36;
      case 'h2':
        return 24;
      case 'h3':
        return 16;
      case 'bodyLower':
        return 14;
      case 'bodyLower2':
        return 12;
      default:
      case 'body':
        return 16;
    }
  };
  const getFontWeight = () => {
    switch (variant) {
      case 'h1':
        return 900;
      case 'h2':
        return 700;
      case 'h3':
        return 700;
      default:
      case 'body':
        return 400;
    }
  };

  return {
    fontFamily: 'Nunito',
    fontWeight: getFontWeight(),
    fontSize: getFontSize(),
    color: getColor(),
  };
};

type TextVariant = 'body' | 'bodyLower' | 'bodyLower2' | 'h1' | 'h2' | 'h3';
type TextColor =
  | 'black10'
  | 'black80'
  | 'white10'
  | 'white20'
  | 'white30'
  | 'white50'
  | 'white60'
  | 'white80'
  | 'white90'
  | 'white100'
  | 'alert60'
  | 'success'
  | 'error'
  | 'warning';

interface StyledTextProps {
  variant?: TextVariant;
  color?: TextColor;
}
export const StyledBodyText = styled('p', {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'color' && prop !== 'sx',
})<StyledTextProps>(({ variant = 'body', color = 'white100', theme }) => ({
  ...commonTextStyled(variant, color),
}));

export const StyledHeaderText = styled('h1', {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'color' && prop !== 'sx',
})<StyledTextProps>(({ variant = 'h1', color = 'white100', theme }) => ({
  ...commonTextStyled(variant, color),
}));

export const StyledHeaderTextH2 = styled('h2', {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'color' && prop !== 'sx',
})<StyledTextProps>(({ variant = 'h2', color = 'white100', theme }) => ({
  ...commonTextStyled(variant, color),
}));

export const StyledWarningText = styled('div')(({ theme: { spacing } }: { theme: Theme }) => ({
  background: COLORS.black[80],
  borderRadius: 10,
  textAlign: 'center',
  padding: spacing(),
  marginBottom: spacing(0.5),
  color: COLORS.warning[100],
}));
