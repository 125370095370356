import * as React from 'react';

import { UploadedFile } from '../../../../../../common/domain/upload';
import {
  GAME_COVERS_RESOLUTIONS,
  GameCoverWithUploadedFile,
  GameCoversWithUploadedFile,
} from '../../../../../../common/graphql/games/game';
import ImageUpload from '../../../../../../common/CzyForm/ImageUpload';
import { COVER_LANDSCAPE_RATIO, COVER_PORTRAIT_RATIO, COVER_SQUARE_RATIO } from '../../../../../../common/thumbnail';
import config from '../../../../../../common/config';

export interface SubmissionUpdateGameCoversProps {
  gameCovers: GameCoversWithUploadedFile | GameCoverWithUploadedFile[];
  errors?: {
    [K in GAME_COVERS_RESOLUTIONS]: boolean;
  };
  onChange: (id: GAME_COVERS_RESOLUTIONS, file: UploadedFile | null) => void;
  isNonEditable?: boolean;
}

const SubmissionUpdateGameCovers: React.FC<SubmissionUpdateGameCoversProps> = ({ gameCovers, onChange, errors, isNonEditable }) => {
  const getImageSource = (size: GAME_COVERS_RESOLUTIONS): string | undefined => {
    let url: string | undefined = undefined;
    if (Array.isArray(gameCovers)) {
      const _gameCovers = gameCovers as GameCoverWithUploadedFile[];
      const gameCover = _gameCovers.find((gc) => gc.size === size);
      url = gameCover?.uploadedFile?.temporaryUrl;
    } else {
      url = gameCovers[size] ? gameCovers[size]?.temporaryUrl : undefined;
    }

    if (url) {
      return `${config.graph}/${url}`;
    }
    return undefined;
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <ImageUpload
        ratio={COVER_LANDSCAPE_RATIO}
        displayRatio={{ height: COVER_LANDSCAPE_RATIO.height / 5, width: COVER_LANDSCAPE_RATIO.width / 5 }}
        onChange={(file) => {
          onChange('16x9', file);
        }}
        imageSource={getImageSource('16x9')}
        minWidth={COVER_LANDSCAPE_RATIO.width}
        minHeight={COVER_LANDSCAPE_RATIO.height}
        error={errors && errors['16x9']}
        isNonEditable={isNonEditable}
        preventInfoText
        label="Landscape 16:9 (1920x1080)"
        oneButtonLayout
      />

      <ImageUpload
        ratio={COVER_PORTRAIT_RATIO}
        displayRatio={{ height: COVER_PORTRAIT_RATIO.height / 4, width: COVER_PORTRAIT_RATIO.width / 4 }}
        onChange={(file) => {
          onChange('2x3', file);
        }}
        imageSource={getImageSource('2x3')}
        minWidth={COVER_PORTRAIT_RATIO.width}
        minHeight={COVER_PORTRAIT_RATIO.height}
        error={errors && errors['2x3']}
        isNonEditable={isNonEditable}
        preventInfoText
        label="Portrait 2:3 (800x1200)"
        oneButtonLayout
      />

      <ImageUpload
        ratio={COVER_SQUARE_RATIO}
        displayRatio={{ height: COVER_SQUARE_RATIO.height / 4, width: COVER_SQUARE_RATIO.width / 4 }}
        onChange={(file) => {
          onChange('1x1', file);
        }}
        imageSource={getImageSource('1x1')}
        minWidth={COVER_SQUARE_RATIO.width}
        minHeight={COVER_SQUARE_RATIO.height}
        error={errors && errors['1x1']}
        isNonEditable={isNonEditable}
        preventInfoText
        label="Square 1:1 (800x800)"
        oneButtonLayout
      />
    </div>
  );
};

export default SubmissionUpdateGameCovers;
