import React from 'react';
import { Grid, FormControl, Checkbox, FormControlLabel, Typography, useTheme } from '@mui/material';
import { getInputValue } from '../../../../common/react';
import { DefaultInput } from '../../../../common/Styleguide/Common/Input';
import { Box } from '@mui/system';
import { AccountData } from './Profile';
import { COLORS } from '../../../../common/Styleguide/Common/colors';

export interface AccountProps {
  accountData: AccountData;
  updateAccountDataFn: (data: AccountData) => void;
}

const Account: React.FC<AccountProps> = ({ accountData, updateAccountDataFn }) => {
  const { spacing } = useTheme();

  return (
    <>
      <Typography variant="h5" sx={{ fontWeight: 700, mb: 2 }}>
        Account
      </Typography>

      <div style={{ fontSize: 16, marginBottom: spacing(2), color: COLORS.white[60] }}>
        The contact name is used in the emails that we send to the provided email address.
      </div>

      <Grid container direction="row">
        <Grid item xs={6}>
          <DefaultInput
            sx={{ mb: 2 }}
            value={accountData.name}
            onChange={(ev) => updateAccountDataFn({ ...accountData, name: getInputValue(ev) })}
            header="Contact name"
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <DefaultInput readOnly value={accountData.email} header="Email" />
        </Grid>
      </Grid>

      <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={accountData.feedback}
                onChange={(ev) => updateAccountDataFn({ ...accountData, feedback: ev.target.checked })}
              />
            }
            label="I want to receive emails with user feedback"
          />
        </FormControl>
      </Box>
    </>
  );
};

export default Account;
