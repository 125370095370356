import { gql } from '@apollo/client';
import { mutate } from '../../../../../../common/graphql';
import { UploadedFileInput } from '../../../../../../common/graphql/upload-input';
import { SubmissionUnity56Encoding } from '../submission-graphql';
import { QAResult } from '../../QATool/components/SubmitManager';
import { GraphGameEngineType } from '../../../../../../common/domain/game';
import { GameCoverWithUploadedFileInput } from '../../../../../../common/graphql/games/game';
import { SubmissionAPSDetail } from '../../SubmitGame/submit-game-graphql';

const UPDATE_RELEASED_SUBMISSION = gql`
  mutation updateReleasedSubmission($input: DeveloperReleasedSubmissionUpdateInput!) {
    developerReleasedSubmissionUpdate(input: $input) {
      id
      filesChanged
      coverChanged
      iframeLinkChanged
      gameEngineType
    }
  }
`;

export interface UpdateReleasedSubmissionMutation {
  developerReleasedSubmissionUpdate: UpdateReleasedSubmissionResult;
}

export interface UpdateReleasedSubmissionResult {
  id: string;
  filesChanged?: boolean;
  coverChanged?: boolean;
  iframeLinkChanged?: boolean;
  qaResult?: QAResult;
}

export interface DeveloperReleasedSubmissionUpdateInput {
  id: string;
  gameFiles: UploadedFileInput[];
  gameCovers: GameCoverWithUploadedFileInput[];
  unity56Encoding?: SubmissionUnity56Encoding;
  iframeLink?: string;
  unitySaveFileNames?: string[];
  gameEngineType?: GraphGameEngineType;
  apsDetail: SubmissionAPSDetail;
}

export function updateReleasedSubmission(input: DeveloperReleasedSubmissionUpdateInput) {
  return mutate<DeveloperReleasedSubmissionUpdateInput, UpdateReleasedSubmissionMutation>(UPDATE_RELEASED_SUBMISSION, input);
}
