import { prefixGraphUrl } from './util';
import { SessionContextData } from './Session/SessionContext';
import { Unity56Encoding } from '../components/App/Content/UserDeveloper/Submission/GameFilesForm/UnityEngineOptions/UnityCompressionSelect';
import { GameEngineType } from './domain/game';

const FILE_ENDPOINT = prefixGraphUrl('upload');
const STANDALONE_COPY_ENDPOINT = prefixGraphUrl('upload/standalone-copy');

export interface PostDataReponse {
  field: string;
  fileName: string;
  size: number;
  uploadId: string;
}

export interface CopyToStandaloneResponse {
  standaloneId: string;
}

export async function uploadFile(file: File, session: SessionContextData, fileName?: string): Promise<PostDataReponse[]> {
  if (!session.isLoggedIn()) {
    return Promise.reject('[upload] not authenticated');
  }
  const token = await session.getUser().firebaseUser.getIdToken();
  const formData = new FormData();
  formData.append('file', file);
  const endpoint = new URL(FILE_ENDPOINT);
  if (fileName) {
    endpoint.searchParams.set('fileName', fileName);
  }
  const resp = await fetch(endpoint.toString(), {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (resp.status === 200) {
    return resp.json();
  } else {
    return resp.text().then((str) => {
      console.error(str);
      return Promise.reject(str);
    });
  }
}

export async function copyToStandalone(
  fileIds: string[],
  session: SessionContextData,
  gameEngine: GameEngineType,
  unityEncoding?: Unity56Encoding,
): Promise<CopyToStandaloneResponse> {
  if (!session.isLoggedIn()) {
    return Promise.reject('[upload/standalone-copy] not authenticated');
  }
  const endpoint = new URL(STANDALONE_COPY_ENDPOINT);
  const token = await session.getUser().firebaseUser.getIdToken();

  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Accept', 'application/json');

  const resp = await fetch(endpoint.toString(), {
    method: 'POST',
    body: JSON.stringify({ fileIds, gameEngine, unityEncoding }),
    headers: myHeaders,
  });
  if (resp.status === 200) {
    return resp.json();
  } else {
    return resp.text().then((str) => {
      throw new Error(str);
    });
  }
}
