import { gql } from '@apollo/client';
import { mutate } from '../../../../../../common/graphql';

const UPDATE_SUBMISSION_SUBMIT_FOR_APPROVAL = gql`
  mutation submissionSubmitForApproval($input: DeveloperSubmissionSubmitForApprovalInput!) {
    developerSubmissionSubmitForApproval(input: $input) {
      id
    }
  }
`;

export interface SubmissionSubmitForApprovalMutation {
  developerSubmissionSubmitForApproval: SubmissionSubmitForApprovalResult;
}

export interface SubmissionSubmitForApprovalResult {
  id: string;
}

export interface DeveloperSubmissionSubmitForApprovalInput {
  id: string;
  revenueShareInputV2?: RevenueShareInputV2;
}

export interface RevenueShareInputV2 {
  isEligible: boolean;
  makeExclusive: boolean;
  allowDistribution: boolean;
  hasImplementedSDK: boolean;
  hasImplementedLogo: boolean;
  disallowEmbedding: boolean;
  suitableForChildren: boolean;
  isInvited: boolean;
  hasCustomContract: boolean;
}

export function submissionSubmitForApproval(input: DeveloperSubmissionSubmitForApprovalInput) {
  return mutate<DeveloperSubmissionSubmitForApprovalInput, SubmissionSubmitForApprovalMutation>(
    UPDATE_SUBMISSION_SUBMIT_FOR_APPROVAL,
    input,
  );
}
