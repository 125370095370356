import React, { useContext } from 'react';
import { StyledBodyText } from '../../../../../../../common/Styleguide/Common/Text';
import { StyledInput } from '../../../../../../../common/Styleguide/Common/Input';
import { GameEngineType, unityEnginesWithDataSave } from '../../../../../../../common/domain/game';
import { HALF_INPUT_WIDTH } from '../../UpdateSubmission/UpdateSubmission.types';
import { SubmissionContext } from '../../Context/SubmissionProvider';

export interface UnitySaveFileNameProps {
  fileName?: string;
  gameEngineType: GameEngineType | null;
  onChange: (fileName: string) => void;
  isNonEditable?: boolean;
}

const UnitySaveFileName: React.FC<UnitySaveFileNameProps> = ({ onChange, fileName, isNonEditable, gameEngineType }) => {
  const { fileFormProblems, attemptedToSave } = useContext(SubmissionContext);
  if (!gameEngineType || !unityEnginesWithDataSave.includes(gameEngineType)) {
    return null;
  }
  const hasApsUnityCustomNameMissingProblem = attemptedToSave && fileFormProblems?.includes('APS_UNITY_CUSTOM_NAME_MISSING');

  return (
    <>
      <StyledBodyText variant="h3" sx={{ mt: 3, mb: 1.25 }}>
        What is the name of the file in which the game stores the data? <span>*</span>
      </StyledBodyText>

      <div>
        <StyledInput
          disabled={isNonEditable}
          placeholder="Type the name of the file (Ex: customdata.dat)"
          error={hasApsUnityCustomNameMissingProblem}
          value={fileName || ''}
          onChange={(evt: any) => onChange(evt.target.value)}
          style={{ width: HALF_INPUT_WIDTH }}
        />
        <StyledBodyText sx={{ m: 0.5, height: 20 }} variant="bodyLower2" color="error">
          {hasApsUnityCustomNameMissingProblem && `Please fill in the input`}
        </StyledBodyText>
      </div>
      <StyledBodyText color="white30" variant="bodyLower">
        Our automatic progress save feature saves PlayerPrefs by default, and syncs it across devices for logged in users. However, if the
        data is stored in a file, we need to know its name to backup it also from IndexedDB. Please be aware that you can add only one
        filename. Including multiple files will not work.
      </StyledBodyText>
    </>
  );
};

export default UnitySaveFileName;
