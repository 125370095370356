import { Divider, useTheme } from '@mui/material';
import * as React from 'react';
import SelectTimeframe, { StatsPeriod } from '../../../../../../common/components/SelectTimeframe/SelectTimeframe';
import { convertPeriodToOption } from '../../../../../../common/datetime';
import { Submission } from '../submission-graphql';
import DeveloperSubmissionStatsDailyBar from './DeveloperSubmissionStatsDailyBar';
import DeveloperSubmissionStatsHeader from './DeveloperSubmissionStatsHeader';
import moment from 'moment';

interface IDeveloperSubmissionStatsProps {
  submission: Submission;
}

const period = 'LAST_6_MONTHS';
const DEFAULT_TIMEFRAME: StatsPeriod = { period, preDefinedTimeframe: true, options: convertPeriodToOption(period) };

const DeveloperSubmissionStats: React.FunctionComponent<IDeveloperSubmissionStatsProps> = ({ submission }) => {
  const [timeframeFilter, setTimeframeFilter] = React.useState<StatsPeriod>(DEFAULT_TIMEFRAME);

  const { spacing } = useTheme();

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: spacing(2) }}>
        <div>
          <p>
            New metrics have been added on August 5. For the existing metrics an improved way of measuring is launched, resulting in slight
            differences.
            <br />
            Timeseries for other stats than revenue will be made available shortly.
          </p>
        </div>
        <div style={{ padding: spacing(2) }}>
          <SelectTimeframe
            minDate={moment('2023-09-01').endOf('day')}
            maxDate={moment()}
            hideLabel
            initialPeriod={timeframeFilter.period}
            onChange={(newTimeframe) => setTimeframeFilter(newTimeframe)}
          />
        </div>
      </div>

      <DeveloperSubmissionStatsHeader submission={submission} timeframeFilter={timeframeFilter} />
      <Divider />
      {timeframeFilter.period !== 'ALL_TIME' && (
        <DeveloperSubmissionStatsDailyBar timeframeFilter={timeframeFilter} submission={submission} />
      )}
    </div>
  );
};

export default DeveloperSubmissionStats;
