import * as React from 'react';
import { generateSubmissionPreviewRoute } from '../../../components/App/Content/Preview/PreviewRoute';
import { StyledButton } from '../../Styleguide/Common/Button';
import { Box } from '@mui/material';
import { StyledBodyText, StyledHeaderTextH2 } from '../../Styleguide/Common/Text';
import { Link } from 'react-router-dom';

export interface PreviewProps {
  submissionId: string;
}

type Props = PreviewProps;

const Preview: React.FC<Props> = ({ submissionId }) => {
  const link = generateSubmissionPreviewRoute(submissionId);
  return (
    <Box sx={{ ml: 2.5, pt: 2 }}>
      <StyledHeaderTextH2 sx={{ mt: 1, mb: 0 }}>Preview your game</StyledHeaderTextH2>
      <StyledBodyText sx={{ mt: 1, mb: 2 }} color="white60">
        You can preview the game by clicking on the button below. Note that the preview tries to match how the game will be when it goes
        live on crazygames.com, however small differences may occur.
      </StyledBodyText>
      <Link to={link.href}>
        <StyledButton variant="contained">Preview</StyledButton>
      </Link>
    </Box>
  );
};

export default Preview;
