/**
 * UNITY 5.5 / 2019 / 2018 / 2017 are only used in the frontend, and converted to 5.4 when sent to graph
 */
export type GameEngineType =
  | 'UNITY_2023'
  | 'UNITY_2022'
  | 'UNITY_2021'
  | 'UNITY_2020'
  | 'UNITY_2019'
  | 'UNITY_2018'
  | 'UNITY_2017'
  | 'UNITY_5_6'
  | 'UNITY_5_5'
  | 'UNITY_5_4'
  | 'IFRAME'
  | 'HTML5';

export type GraphGameEngineType =
  | 'UNITY_2023'
  | 'UNITY_2022'
  | 'UNITY_2021'
  | 'UNITY_2020'
  | 'UNITY_5_6'
  | 'UNITY_5_4'
  | 'IFRAME'
  | 'HTML5';

export const gameEngineTypes: GameEngineType[] = [
  'UNITY_2023',
  'UNITY_2022',
  'UNITY_2021',
  'UNITY_2020',
  'UNITY_2019',
  'UNITY_2018',
  'UNITY_2017',
  'UNITY_5_6',
  'UNITY_5_5',
  'UNITY_5_4',
  'IFRAME',
  'HTML5',
];

/** These Unity versions allow the devs to select brotli/gzip compression */
export const unityGameEnginesWithCompression: GameEngineType[] = [
  'UNITY_5_6',
  'UNITY_2017',
  'UNITY_2018',
  'UNITY_2019',
  'UNITY_2020',
  'UNITY_2021',
  'UNITY_2022',
  'UNITY_2023',
];

/**
 * These game engines versions display the toggles so the dev can tell us how the game saves data,
 * and also allow displaying a custom file name field, for when the game stores data in file.
 */
export const unityEnginesWithDataSave: GameEngineType[] = [
  'UNITY_5_6',
  'UNITY_2017',
  'UNITY_2018',
  'UNITY_2019',
  'UNITY_2020',
  'UNITY_2021',
  'UNITY_2022',
  'UNITY_2023',
];

/** These game engines versions display the toggles so the dev can tell us how the game saves data. */
export const enginesWithSaveDataToggles: GameEngineType[] = ['HTML5', 'IFRAME', ...unityEnginesWithDataSave];

/** These Unity versions benefit from the sitelock */
export const gameEnginesWithSitelock: GameEngineType[] = ['UNITY_2023', 'UNITY_2022', 'UNITY_2021', 'UNITY_2020', 'UNITY_5_6'];

export const standaloneGameEngineTypes: GameEngineType[] = [
  'UNITY_2023',
  'UNITY_2022',
  'UNITY_2021',
  'UNITY_2020',
  'UNITY_5_6',
  'IFRAME',
  'HTML5',
];

export function renderGameEngineType(gameEngineType: GameEngineType): string {
  switch (gameEngineType) {
    case 'UNITY_2023':
      return 'Unity 2023';
    case 'UNITY_2022':
      return 'Unity 2022';
    case 'UNITY_2021':
      return 'Unity 2021';
    case 'UNITY_2020':
      return 'Unity 2020';
    case 'UNITY_2019':
      return 'Unity 2019';
    case 'UNITY_2018':
      return 'Unity 2018';
    case 'UNITY_2017':
      return 'Unity 2017';
    case 'UNITY_5_6':
      return 'Unity 5.6';
    case 'UNITY_5_5':
      return 'Unity 5.5';
    case 'UNITY_5_4':
      return 'Unity 5.4';
    case 'IFRAME':
      return 'IFrame';
    case 'HTML5':
      return 'HTML5';
    default:
      throw new Error(`Unmapped GameType: ${gameEngineType}`);
  }
}

export function convertGameEngineToGraph(gameEngine: GameEngineType): GraphGameEngineType {
  if (gameEngine === 'UNITY_5_5') {
    return 'UNITY_5_4';
  }
  if (gameEngine === 'UNITY_2019' || gameEngine === 'UNITY_2018' || gameEngine === 'UNITY_2017') {
    return 'UNITY_5_6';
  }
  return gameEngine;
}
