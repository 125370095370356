import { SubmissionStatus } from '../../../../../../common/domain/submission';
import { DOCS_URL } from '../../../../SideMenu/Menu';

export const ALERT_TEXT = 'You have unsaved updated files. Are you sure you want to leave?';
export const NON_RELEASED_STATUSES: SubmissionStatus[] = [
  'NEW_SUBMISSION',
  'DRAFT',
  'REQUIRES_APPROVAL',
  'AWAITING_DEVELOPER_CHANGE',
  'NEW_BUSINESS_REVIEW',
  'UNDER_BUSINESS_REVIEW',
];
export const REAPPROVAL_ALLOWED_STATUSES: SubmissionStatus[] = ['AWAITING_DEVELOPER_CHANGE', 'SYNC_REQUIRED', 'RELEASED'];
export const GAME_GUIDELINES_URL = `${DOCS_URL}/requirements/quality/`;
export const HALF_INPUT_WIDTH = 500;
