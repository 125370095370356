import { styled, Theme } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createMixins';
import { COLORS } from './colors';
import { Option, SelectOption, selectClasses, optionClasses, SelectProps, Select, SelectRootSlotProps, SelectSlots } from '@mui/base';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { commonTextStyled } from './Text';
import { scrollbarStyles } from '../../../components/App/theme';
import CloseIcon from '@mui/icons-material/Close';

const selectButtonStyles = (theme: Theme): CSSProperties => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  ...commonTextStyled('body', 'white80'),
  padding: theme.spacing(),
  paddingLeft: theme.spacing(2),
  boxSizing: 'border-box',
  border: `2px solid ${COLORS.black[30]}`,
  outline: 'none',
  borderRadius: '8px',
  height: 50,
  '& .IconExpandSelect': {
    display: 'block',
    marginLeft: 'auto',
  },
  '& .IconCollapseSelect': {
    display: 'none',
    marginLeft: 'auto',
  },
  minWidth: 190,
  width: '100%',
  '& svg': {
    height: 18,
    width: 18,
    marginRight: theme.spacing(0.5),
  },
});

const selectOutlinedButtonStyles = (theme: Theme, error?: boolean): CSSProperties => ({
  background: 'transparent',
  '&:hover': {
    cursor: 'pointer',
    borderColor: COLORS.white[80],
  },
  '& svg': {
    ...(selectButtonStyles(theme)['& svg'] as CSSProperties),
  },
  [`&.${selectClasses.expanded}`]: {
    '& .IconExpandSelect': {
      display: 'none',
    },
    '& .IconCollapseSelect': {
      display: 'block',
    },
    borderColor: COLORS.white[80],
  },
  [`&.${selectClasses.disabled}`]: {
    background: COLORS.black[70],
    borderColor: 'transparent',
    '&:hover': {
      cursor: 'default',
      borderColor: 'transparent',
    },
  },
  ...(error && {
    borderColor: COLORS.alert[100],
  }),
});

interface StyledDropdownOutlinedButtonProps {
  error?: boolean;
}

export const StyledDropdownOutlinedButton = styled('button')<StyledDropdownOutlinedButtonProps>(
  ({ theme, error }: { theme: Theme; error?: boolean }) => ({
    ...selectButtonStyles(theme),
    ...selectOutlinedButtonStyles(theme, error),
  }),
);

const StyledSelectPlaceholder = styled('div')({ color: COLORS.white[30] });

export const DropdownOutlinedButton = React.forwardRef(function CustomSelectRoot<OptionValue extends {}, Multiple extends boolean = false>(
  props: SelectRootSlotProps<OptionValue, Multiple> & { error?: boolean; placeholder?: string },
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  return (
    <StyledDropdownOutlinedButton type="button" {...props} ref={ref}>
      {props.placeholder && !props.ownerState.value && <StyledSelectPlaceholder>{props.placeholder}</StyledSelectPlaceholder>}
      {props.children}
      <ExpandLessIcon className="IconCollapseSelect" />
      <ExpandMoreIcon className="IconExpandSelect" />
    </StyledDropdownOutlinedButton>
  );
});

export const StyledListbox = styled('ul')(({ theme: { spacing } }: { theme: Theme }) => ({
  borderRadius: 8,
  overflow: 'auto',
  minWidth: 300,
  background: COLORS.black[50],
  color: COLORS.white[80],
  ...(scrollbarStyles(4) as any),
  boxShadow: `0px 0px 2px black`,
  padding: 0,
  maxHeight: 'min(60vh, 400px)',
  display: 'flex',
  flexDirection: 'column',
  '& li': {
    padding: spacing(0, 2),
    display: 'block',
    height: 40,
    lineHeight: '40px',
    '&:hover': {
      cursor: 'pointer',
      background: COLORS.brand[100],
    },
    [`&.${optionClasses.disabled}`]: {
      color: COLORS.white[20],
    },

    [`&.${optionClasses.highlighted}`]: {
      background: COLORS.brand[70],
    },
    [`&.${optionClasses.selected}`]: {
      background: COLORS.brand[100],
    },
  },
}));
const StyledSelectPopup = styled('div')({
  // the dropdown uses a custom popup, so use higher z-index than MUI dialog to appear above it
  zIndex: 1301,
});

export const CustomSelect = React.forwardRef(function CustomSelect<OptionValue extends {}, Multiple extends boolean>(
  props: SelectProps<OptionValue, Multiple> & { error?: boolean; placeholder?: string; onlyPlaceholder?: boolean },
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const slots: SelectSlots = {
    root: DropdownOutlinedButton,
    listbox: StyledListbox,
    popup: StyledSelectPopup,
    ...props.slots,
  };
  return <Select {...props} ref={ref} slots={slots} {...props} />;
});

interface DefaultMultiSelectData {
  value: string;
  name: string;
}

export interface DefaultMultiSelectProps {
  values: string[];
  placeholder?: string;
  data: DefaultMultiSelectData[];
  disabled?: boolean;
  onChange?: (newValues: string[]) => void;
}

export const DefaultMultiSelect: React.FC<DefaultMultiSelectProps> = ({ values, onChange, placeholder, data, disabled }) => {
  return (
    <CustomSelect
      multiple
      style={{ minHeight: 50, height: 'auto', padding: '4px 8px' }}
      renderValue={(option: any) => {
        return renderValue(option, !!disabled, values, onChange);
      }}
      value={values}
      onChange={(_e: any, newValues: any) => {
        if (onChange) {
          onChange(newValues);
        }
      }}
      placeholder={placeholder}
    >
      {data.map((singleData) => {
        return (
          <Option key={singleData.value} value={singleData.value}>
            {singleData.name}
          </Option>
        );
      })}
    </CustomSelect>
  );
};

const renderValue = (option: SelectOption<any>[], disabled: boolean, values: string[], onChange?: (newValues: string[]) => void) => {
  if (option == null) {
    return null;
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      {option.map((singleOption) => (
        <StyledSelectedValue
          key={singleOption.value}
          onClick={(e) => {
            e.preventDefault();
            if (disabled || !onChange) {
              return;
            }
            const newValues = [...values];
            onChange(newValues.filter((newValue) => newValue !== singleOption.value));
          }}
        >
          {singleOption.label}
          <CloseIcon style={{ height: '100%' }} />
        </StyledSelectedValue>
      ))}
    </div>
  );
};

const StyledSelectedValue = styled('div')({
  margin: 4,
  borderRadius: 8,
  paddingLeft: 8,
  paddingRight: 8,
  border: `2px solid ${COLORS.white[100]}`,
  display: 'flex',
});
