import { Dialog, DialogActions, DialogContent, DialogTitle, Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useContext, useState } from 'react';
import { QAToolContext } from '../../QAToolProvider';
import { sdkAdsFeatures } from '../Requirements/SDKRequirements';
import { StyledButton } from '../../../../../../../common/Styleguide/Common/Button';
import { StyledBodyText } from '../../../../../../../common/Styleguide/Common/Text';
import { StyledInput } from '../../../../../../../common/Styleguide/Common/Input';
import { COLORS } from '../../../../../../../common/Styleguide/Common/colors';
import Warning from '../../../../../../../common/icons/Warning';
import { DOCS_URL } from '../../../../../SideMenu/Menu';

const ADS_URL = `${DOCS_URL}/requirements/ads/`;

interface SubmissionInfoPopupProps {
  next: () => void;
  back: () => void;
  isDraft: boolean;
}
const SubmissionInfoPopup: React.FC<SubmissionInfoPopupProps> = ({ next, back, isDraft }) => {
  const { updateNote, adScenario, setData, sdkYesNoRequirements } = useContext(QAToolContext);
  const [expandAdScenarioInfo, setExpandAdScenarioInfo] = useState(false);

  const hasAds = sdkAdsFeatures.some((sdkAdFeature) => {
    return sdkYesNoRequirements[sdkAdFeature] === 'ok';
  });

  let isSubmitDisabled = false;

  if (!isDraft && updateNote.length < 5) {
    isSubmitDisabled = true;
  }
  if (hasAds && adScenario.length < 5) {
    isSubmitDisabled = true;
  }

  const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const note = event.target.value;
    setData({ updateNote: note });
  };

  const handleAdScenarioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const adScenario = event.target.value;
    setData({ adScenario });
  };

  const handleToggleInfo = () => setExpandAdScenarioInfo(!expandAdScenarioInfo);

  return (
    <Dialog open onClose={back}>
      <DialogTitle id="alert-dialog-slide-title">Update Notes</DialogTitle>
      <DialogContent sx={{ pb: hasAds ? 0 : 2 }}>
        {!isDraft && (
          <>
            <StyledBodyText color="white60">Shown only to CrazyGames admins, not to end users.</StyledBodyText>
            <StyledInput
              multiline
              placeholder="(Add at least 5 characters)"
              defaultValue={updateNote}
              onChange={handleNotesChange}
              height={107}
            />
          </>
        )}
        {hasAds && (
          <>
            <DialogTitle id="alert-dialog-slide-title">Ads scenario</DialogTitle>
            <StyledBodyText color="white60" sx={{ textAlign: 'center', px: 2, mt: 0 }}>
              Make sure to follow our{' '}
              <a href={ADS_URL} target="_blank" rel="noopener noreferrer">
                Ad guidelines
              </a>{' '}
              properly.
            </StyledBodyText>
            <Box sx={{ minWidth: 530, borderRadius: 2, p: 2, mb: 2, width: 1, background: COLORS.black[80] }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Warning />
                <StyledBodyText color="white50" variant="bodyLower" sx={{ fontWeight: 700, flex: 1, ml: 2, my: 0 }}>
                  Avoid these common mistakes to prevent <span style={{ color: COLORS.alert[100] }}>rejection</span>
                </StyledBodyText>
                <Box onClick={handleToggleInfo} sx={{ '&:hover': { cursor: 'pointer' }, display: 'flex', fontWeight: 700 }}>
                  <StyledBodyText sx={{ m: 0 }} onClick={handleToggleInfo} color="white50" variant="bodyLower">
                    {expandAdScenarioInfo ? 'Hide' : 'Show'}
                  </StyledBodyText>
                  <KeyboardArrowDownIcon sx={{ mt: '-1px', ml: '2px' }} />
                </Box>
              </div>

              {expandAdScenarioInfo && (
                <ul style={{ marginTop: 0 }}>
                  <li>
                    <StyledBodyText color="white50" variant="bodyLower" sx={{ mt: 1 }}>
                      Presenting midgame and rewarded ads together in the same scene (e.g., a Revive button calls a rewarded ad, and the
                      Skip button on that scene calls a <strong>midgame ad</strong>). Use only one of them in the same scene.
                    </StyledBodyText>
                  </li>
                  <li>
                    <StyledBodyText color="white50" variant="bodyLower" sx={{ mt: 1 }}>
                      Calling a midgame ad on navigational buttons such as main menu and settings.
                    </StyledBodyText>
                  </li>
                </ul>
              )}
            </Box>
            <StyledInput
              multiline
              placeholder={`Write in English where you have ads in the game. Describe in detail`}
              defaultValue={adScenario}
              onChange={handleAdScenarioChange}
              height={107}
            />
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ pt: 2, pb: 3 }}>
        <StyledButton onClick={back} variant="contained" color="grey" height={50} sx={{ minWidth: 208, mr: 2 }}>
          Back to preview
        </StyledButton>
        <StyledButton onClick={next} variant="contained" color="purple" height={50} style={{ minWidth: 208 }} disabled={isSubmitDisabled}>
          Submit
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default SubmissionInfoPopup;
