import { useTheme } from '@mui/material';
import * as React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import { DeveloperDailyRevenueStat } from '../../Games/userDeveloperSubmission-graphql';
import { useQuery } from '@apollo/client';
import { MY_GAME_DAILY_STATS_QUERY, MyGameDailyStatsQuery } from '../submission-graphql';
import { StatsPeriod } from '../../../../../../common/components/SelectTimeframe/SelectTimeframe';
import { Submission } from '../submission-graphql';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
interface DeveloperSubmissionStatsDailyBarProps {
  submission: Submission;
  timeframeFilter: StatsPeriod;
}

const DeveloperSubmissionStatsDailyBar: React.FunctionComponent<DeveloperSubmissionStatsDailyBarProps> = ({
  submission,
  timeframeFilter,
}) => {
  const theme = useTheme();

  const { data: dailyGameData } = useQuery<MyGameDailyStatsQuery>(MY_GAME_DAILY_STATS_QUERY, {
    variables: { slug: submission.slug, options: timeframeFilter.options },
  });

  const chartRawData = dailyGameData ? [...dailyGameData.gameBySlug.dailyRevenueStats].reverse() : [];
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            return `My revenue: ${context.parsed.y.toLocaleString('nl-BE', { style: 'currency', currency: 'EUR' })}`;
          },
        },
      },
    },
  };

  const labels = chartRawData.map!((stats: DeveloperDailyRevenueStat) => moment(stats.date).format('Do MMM'));
  const chartData = {
    labels,
    datasets: [
      {
        data: chartRawData.map!((stats: DeveloperDailyRevenueStat) => stats.revenueShareDaily.total),
        backgroundColor: '#1BECBA',
      },
    ],
  };

  return (
    <div style={{ padding: theme.spacing(2) }}>
      <Bar options={chartOptions} data={chartData} />
    </div>
  );
};

export default DeveloperSubmissionStatsDailyBar;
