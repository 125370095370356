import { FilesFormData, validateFilesForm, buildDefaultFilesFormData } from '../Submission/GameFilesForm/FilesFormData';
import { InfoFormData, buildDefaultInfoFormData, validateInfoForm } from '../Submission/GameInfoForm/InfoFormData';
import {
  RevenueShareFormData,
  buildDefaultRevenueShareFormData,
  validateRevenueShareForm,
} from '../Submission/RevenueShareForm/RevenueShareFormData';
import { DeveloperSubmissionCreateInput } from './submit-game-graphql';
import {
  convertGameEngineToGraph,
  GameEngineType,
  unityGameEnginesWithCompression,
  enginesWithSaveDataToggles,
} from '../../../../../common/domain/game';
import { UploadedFile } from '../../../../../common/domain/upload';
import { UploadedFileInput, convertGameCoversToInput } from '../../../../../common/graphql/upload-input';
import { RevenueShareInputV2 } from '../Submission/Mutations/update-submission-revenue-mutation';
import { FilesFormProblem, InfoFormProblem } from './submission-errors';

export type SubmissionStep = 'DATA_FORM' | 'REVENUE_SHARE_FORM';

export interface SubmissionData {
  infoForm: InfoFormData;
  filesForm: FilesFormData;
  revenueShareForm: RevenueShareFormData;
  infoFormProblems?: InfoFormProblem[];
  fileFormProblems: FilesFormProblem[];
  revenueShareFormValid: boolean;
}

export function buildEmptySubmission(): SubmissionData {
  return {
    infoForm: buildDefaultInfoFormData(),
    filesForm: buildDefaultFilesFormData(),
    revenueShareForm: buildDefaultRevenueShareFormData(),
    infoFormProblems: [],
    fileFormProblems: [],
    revenueShareFormValid: false,
  };
}

function uploadedFileToInput(file: UploadedFile): UploadedFileInput {
  const { path, size, uploadId } = file;
  return { path, size, uploadId };
}

export function revenueShareFormToInput(form: RevenueShareFormData): RevenueShareInputV2 {
  const {
    isEligible,
    makeExclusive,
    allowDistribution,
    hasImplementedLogo,
    hasImplementedSDK,
    disallowEmbedding,
    suitableForChildren,
    hasCustomContract,
    isInvited,
  } = form;
  return {
    isEligible,
    makeExclusive,
    allowDistribution,
    hasImplementedLogo,
    hasImplementedSDK,
    disallowEmbedding,
    suitableForChildren,
    isInvited,
    hasCustomContract,
  };
}

export function submissionToGraphQLGame(
  isNewSubmission: boolean,
  infoForm: InfoFormData,
  filesForm: FilesFormData,
  revenueShareForm?: RevenueShareFormData,
): DeveloperSubmissionCreateInput {
  const problems = [
    validateInfoForm(infoForm),
    validateFilesForm(filesForm),
    revenueShareForm && validateRevenueShareForm(revenueShareForm),
  ];

  if (!problems.every((form) => !form || form.length === 0)) {
    console.error('form was invalid', problems);
    throw new Error('INVALID_FORM');
  }

  const unityEncoding =
    filesForm.gameEngineType && unityGameEnginesWithCompression.includes(filesForm.gameEngineType) ? filesForm.unity56Encoding : undefined;

  const hasAPSSupport = filesForm.gameEngineType && enginesWithSaveDataToggles.includes(filesForm.gameEngineType);
  const unitySaveFileName = filesForm.unitySaveFileName;

  return {
    gameName: infoForm.name?.trim() as string,
    description: infoForm.description as string,
    controls: infoForm.controls as string,
    playStoreLink: infoForm.playUrl as string,
    appStoreLink: infoForm.appstoreUrl as string,
    steamStoreLink: infoForm.steamUrl as string,
    categoryId: infoForm.category as string,
    playStoreDownloads: parseInt(`${infoForm.playStoreDownloads}`) || undefined,
    appStoreDownloads: parseInt(`${infoForm.appStoreDownloads}`) || undefined,
    steamDownloads: parseInt(`${infoForm.steamDownloads}`) || undefined,
    hasIAP: filesForm.hasIAP,
    isChromebookFriendly: filesForm.isChromebookFriendly,
    multiplayerOptions: filesForm.multiplayerOptions,

    gameEngineType: convertGameEngineToGraph(filesForm.gameEngineType as GameEngineType),
    tagIds: infoForm.tags as string[],
    fullscreenable: filesForm.isFullscreenable,
    isIOSFriendly: !!filesForm.isIOSFriendly,
    isAndroidFriendly: !!filesForm.isAndroidFriendly,
    orientation: (filesForm.isIOSFriendly || filesForm.isAndroidFriendly) && filesForm.orientation ? filesForm.orientation : undefined,
    // we dont reset gameFiles when changing engine type so the user is not frustrated when uploading files, selecting iframe
    // and then selecting unity again. We reset them here if needed
    gameFiles: filesForm.gameEngineType !== 'IFRAME' && filesForm.files ? filesForm.files.map(uploadedFileToInput) : [],
    iframeLink: filesForm.gameEngineType === 'IFRAME' && filesForm.iframeLink ? filesForm.iframeLink.trim() : undefined,
    unity56Encoding: unityEncoding,
    gameCovers: convertGameCoversToInput(filesForm.gameCovers),
    // front end is now handling a single file name, while backend is storing them as an array
    unitySaveFileNames: hasAPSSupport && unitySaveFileName ? [unitySaveFileName] : undefined,
    // this flag is required only for new submissions, when updating the backend will smartly handle submissions with or without builds
    newBuildSystem: isNewSubmission ? window.location.href.includes('new_build_system=true') : undefined,
    apsDetail: hasAPSSupport
      ? {
          progressType: filesForm.progressSaveType || 'UNKNOWN',
        }
      : {},
  };
}
