import * as React from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, IconButton, Paper } from '@mui/material';
import { FixedSizeList } from 'react-window';
import DropZone, { DroppedFile } from './DropZone';
import { uploadFile } from '../upload';
import { imageFileAsDataUrl, isImageFile } from '../file';
import { UploadedFile } from '../domain/upload';
import { config } from '../config';
import Download from './Download';
import withSession, { WithSession } from '../Session/withSession';
import { StyledBodyText, StyledHeaderText } from '../Styleguide/Common/Text';
import { StyledButton } from '../Styleguide/Common/Button';
import Upload from '../icons/Upload';
import { COLORS } from '../Styleguide/Common/colors';
import Delete from '../icons/Delete';
import AutoSizer from 'react-virtualized-auto-sizer';
import styled from '@emotion/styled';
import { scrollbarStyles } from '../../components/App/theme';

type UploadFileState = 'WAITING' | 'UPLOADING' | 'DONE' | 'IGNORED';

const JUNK_PATTERNS: RegExp[] = [
  // # All
  /\.htaccess$/,
  /\.log$/,
  /node_modules/,
  /.swp$/, // Swap file for vim state
  // # macOS
  /\.DS_STORE/,
  /AppleDouble/, // Stores additional file resources
  /LSOverride/, // Contains the absolute path to the app to be used
  /Icon\\r/, // Custom Finder icon: http://superuser.com/questions/298785/icon-file-on-os-x-desktop
  /\.Spotlight-V100(?:$|\/)/, // Directory that might appear on external disk
  /\.Trashes/, // File that might appear on external disk
  /__MACOSX/, // Resource fork
  // # Linux
  /~$/, // Backup file
  // # Windows
  /Thumbs\.db/, // Image file cache
  /ehthumbs\.db/, // Folder config file
  /Desktop\.ini/, // Stores custom folder attributes
  /@eaDir/, // Synology Diskstation "hidden" folder where the server stores thumbnails
];

interface UploadFile {
  state: UploadFileState;
  path: string;
  size: number;
  blob?: File;
  uploadId?: string;
  imageUrl: string | null;
}

interface UploadDropZoneProps extends WithSession {
  /**
   * Handler that is called when the upload of a set of dropped files is completed or modified
   * This will be called when a file is removed as well (and wont trigger an uploads complete cb)
   */
  onFilesModified: (files: UploadedFile[]) => void;

  /**
   * Handler that is called when no uploads are running
   * Will be called once when no more uploads are being processed
   */
  onAllUploadsComplete?: () => void;

  /**
   * Called when new files are dropped.
   * Can be called multiple times before uploadsComplete is called
   */
  onUploadsStarted?: () => void;

  /**
   * What should happen when new files are dropped? Append will add the dropped files to the set of already uploaded files.
   * Replace will remove the set of already uploaded files and replace it with a new set
   */
  dropBehaviour: 'APPEND' | 'REPLACE';

  /**
   * Initial list of uploaded files which will be rendered as completed
   */
  initialFiles?: UploadedFile[];

  /**
   * Only allow files that have the following extension or match a regexp
   * Based on the name of the file
   */
  allowedExtensions?: (string | RegExp)[];

  /**
   * Reject files that have the following extension or match a regexp
   * Based on the name of the file
   */
  disallowedExtensions?: (string | RegExp)[];

  /**
   * Limit the number of files that can be uploaded
   */
  maxFiles?: number;

  /**
   * Limit the maximum size for a single file in MB
   */
  maxFileSize?: number;

  /**
   * Disable the upload zone
   */
  dropDisabled?: boolean;

  /**
   * Text to show when there aren't any files yet
   */
  noFilesText?: string;

  /**
   * Error from outside
   */
  error?: boolean;

  isNonEditable?: boolean;

  /**
   * Enables downloading individual files.
   * Pass function that takes upload id as input and returns download url
   * You can use submission-graphql/getFileDownloadUrl, which will return the s3 url
   */
  getFileDownloadUrl?: (uploadId: string) => Promise<string>;
}

interface UploadDropZoneState {
  files: UploadFile[];
  ignoredFiles: UploadFile[];
  errors: string[];
}

// for customizing the scrollbar on react-window
const CustomScrollbar = styled('div')({ ...(scrollbarStyles(4) as any) });
const OuterElement = React.forwardRef<HTMLDivElement>((props, ref) => <CustomScrollbar ref={ref} {...props}></CustomScrollbar>);

class UploadDropZone extends React.Component<UploadDropZoneProps, UploadDropZoneState> {
  private files: UploadFile[];
  private ignoredFiles: UploadFile[];

  private filesToUpload: UploadFile[];

  private uploadLoopId: number;

  private maxNumberOfParallelUploads = 10;
  private numberOfFilesBeingUploaded = 0;

  constructor(props: UploadDropZoneProps) {
    super(props);
    this.files = this.getInitialUploadFiles();
    this.filesToUpload = [];
    this.ignoredFiles = [];
    this.uploadLoopId = 0;
    this.state = this.initialState();
  }

  render() {
    const filesToRender = this.state.files.concat(this.state.ignoredFiles);
    const { error } = this.props;
    return (
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        {this.renderRemoveAll()}
        <DropZone
          error={error}
          onFilesDropped={(files) => this.handleFilesDropped(files)}
          dropDisabled={this.props.dropDisabled}
          onError={this.onError}
        >
          {filesToRender.length > 0 ? this.renderFiles(filesToRender) : this.renderNoFiles()}
        </DropZone>
        {this.renderErrorDialog()}
      </div>
    );
  }

  private getImageUrl(uploadedFile: UploadedFile): string | null {
    if (isImageFile(uploadedFile.path) && uploadedFile.temporaryUrl) {
      return `${config.graph}/${uploadedFile.temporaryUrl}`;
    } else {
      return null;
    }
  }

  private onError = (error: any) => {
    const errorMessage = error instanceof Error ? error.message : `Unknown file upload error ${error}`;
    const newErrors = [...this.state.errors, errorMessage];
    this.setState({ errors: newErrors });
  };

  private getInitialUploadFiles(): UploadFile[] {
    const { initialFiles } = this.props;
    if (!initialFiles) {
      return [];
    }
    return initialFiles.map((uploadedFile) => {
      return {
        state: 'DONE',
        path: uploadedFile.path,
        size: uploadedFile.size,
        uploadId: uploadedFile.uploadId,
        imageUrl: this.getImageUrl(uploadedFile),
        blob: undefined,
      } as UploadFile;
    });
  }

  private handleFilesDropped(files: DroppedFile[]) {
    if (this.props.dropDisabled) {
      return;
    }
    // handle replace dropBehaviour
    const { dropBehaviour } = this.props;
    if (dropBehaviour === 'REPLACE') {
      this.files = [];
      this.filesToUpload = [];
      // indicate the upload of a previous set of dropped files can stop
      this.uploadLoopId = this.uploadLoopId + 1;
    }
    this.queueDroppedFiles(files);
  }

  private async startUploadLoop(uploadLoopId: number): Promise<void> {
    if (this.uploadLoopId !== uploadLoopId) {
      // the uploadLoop was restarted (REPLACE dropBehaviour)
      return;
    }
    // grab the file, mark it as UPLOADING and refresh UI
    const numberOfFilesToUpload = this.maxNumberOfParallelUploads - this.numberOfFilesBeingUploaded;
    if (numberOfFilesToUpload <= 0) {
      return;
    }
    const files = this.filesToUpload.splice(0, numberOfFilesToUpload);
    await Promise.all(
      files.map((file) => {
        return this.uploadSingleFile(file, uploadLoopId);
      }),
    );
  }

  private async uploadSingleFile(file: UploadFile, uploadLoopId: number) {
    if (!file.blob) {
      throw new Error('File from filesToUpload had an undefined blob');
    }
    this.numberOfFilesBeingUploaded += 1;
    file.state = 'UPLOADING';
    this.forceUpdate();
    const res = await uploadFile(file.blob, this.props.session, file.path);
    this.numberOfFilesBeingUploaded -= 1;
    const uploadResponse = res[0];
    file.state = 'DONE';
    file.uploadId = uploadResponse.uploadId;
    if (this.uploadLoopId !== uploadLoopId) {
      return;
    }
    this.forceUpdate();
    // check if we are done
    if (this.filesToUpload.length === 0) {
      // when users drop multiple times multiple upload loops can be in progress
      const noOtherUploadInProgress = !this.files.some((f) => f.state === 'UPLOADING');
      if (noOtherUploadInProgress) {
        // No more files to upload => notify about the completion of the upload
        this.notifyFilesModified(this.files);
        this.notifyUploadsComplete();
      }
      return;
    } else {
      // continue the loop
      return this.startUploadLoop(uploadLoopId);
    }
  }

  private async queueDroppedFiles(files: DroppedFile[]) {
    const errors: string[] = [];
    // remove 'old' files with the same path
    for (const file of files) {
      const existing = this.files.find((other) => other.path === file.path);
      if (existing) {
        this.removeFile(existing);
      }
    }
    // check maxFiles will not exceed
    const { maxFiles } = this.props;
    if (maxFiles && this.files.length + files.length > maxFiles) {
      errors.push(`You are only allowed to upload ${maxFiles} file(s).`);
    }
    const filteredFiles: DroppedFile[] = [];
    const ignored: DroppedFile[] = [];
    for (const file of files) {
      const path = file.path;
      // filter out files with invalid extensions
      if (!this.fileIsAllowed(path)) {
        errors.push(`The extension for ${path} is not allowed`);
        continue;
      }
      // skip known junk files
      if (JUNK_PATTERNS.some((rx) => rx.test(path))) {
        ignored.push(file);
        continue;
      }
      filteredFiles.push(file);
    }
    const newFiles = await this.droppedFilesToUploadFiles(filteredFiles);
    const ignoredFiles = ignored.map((file) => {
      return {
        state: 'IGNORED',
        path: file.path,
        size: 0,
      } as UploadFile;
    });
    // make sure there there was something dropped that was not ignored
    if (newFiles.length === 0 && errors.length === 0) {
      errors.push('There were no valid files');
    }
    // if there were any errors report them before proceeding
    if (errors.length > 0) {
      this.setState({ errors: errors });
      return;
    }
    // add the dropped files and queue them for uploading
    this.files = this.files.concat(newFiles);
    this.ignoredFiles = this.ignoredFiles.concat(ignoredFiles);
    this.filesToUpload = this.filesToUpload.concat(newFiles);
    // update the state so the UI is aware of the change
    this.setState({
      files: this.files,
      ignoredFiles: this.ignoredFiles,
    });
    // start uploading the newly dropped files
    this.notifyUploadStarted();
    this.startUploadLoop(this.uploadLoopId);
  }

  private fileIsAllowed(path: string) {
    return this.fileMatchesAllowed(path) && !this.fileMatchesDisallowed(path);
  }

  private fileMatchesAllowed(path: string) {
    const { allowedExtensions } = this.props;
    if (!allowedExtensions || allowedExtensions.length === 0) {
      return true;
    }
    return allowedExtensions.some((ext) => {
      if (ext instanceof RegExp) {
        return ext.test(path);
      }
      return path.toLowerCase().endsWith(ext);
    });
  }

  private fileMatchesDisallowed(path: string) {
    const { disallowedExtensions } = this.props;
    if (!disallowedExtensions || disallowedExtensions.length === 0) {
      return false;
    }
    return disallowedExtensions.some((ext) => {
      if (ext instanceof RegExp) {
        return ext.test(path);
      }
      return path.toLowerCase().endsWith(ext);
    });
  }

  private notifyFilesModified(files: UploadFile[]): void {
    const uploaded = this.uploadFilesToUploadedFiles(files);
    this.props.onFilesModified(uploaded);
  }

  private notifyUploadsComplete(): void {
    const callback = this.props.onAllUploadsComplete;
    if (callback) {
      callback();
    }
  }

  private notifyUploadStarted(): void {
    const callback = this.props.onUploadsStarted;
    if (callback) {
      callback();
    }
  }

  private droppedFilesToUploadFiles(files: DroppedFile[]): Promise<UploadFile[]> {
    const readImages = files.length < 50;
    return Promise.all(
      files.map(async (file) => {
        const imageUrl = isImageFile(file.path) && readImages ? await imageFileAsDataUrl(file.file) : undefined;
        return {
          state: 'WAITING',
          path: file.path,
          size: file.file.size,
          imageUrl: imageUrl,
          blob: file.file,
        } as UploadFile;
      }),
    );
  }

  private uploadFilesToUploadedFiles(files: UploadFile[]): UploadedFile[] {
    return files.map((file) => {
      if (!(file.uploadId || file.state === 'IGNORED')) {
        throw new Error('Cannot convert not uploaded file to an uploaded one');
      }
      return {
        path: file.path,
        size: file.size,
        uploadId: file.uploadId,
        // If this field needs to be used, populate with blob->dataUrl
        temporaryUrl: '',
      } as UploadedFile;
    });
  }

  private renderNoFiles() {
    const noFilesText = this.props.noFilesText;
    return (
      <Grid container={true} alignItems="center" justifyContent="center" style={{ height: '100%' }}>
        <StyledBodyText sx={{ textAlign: 'center', maxWidth: 494 }} color="white30">
          {noFilesText ? noFilesText : 'Drag files and folders here'}
        </StyledBodyText>
      </Grid>
    );
  }

  private renderFiles(files: UploadFile[]) {
    const renderFile = ({ index, style }: { index: number; style: React.CSSProperties }) => {
      const file = files[index];
      return (
        <Grid container={true} alignItems={'center'} key={file.path} sx={{ mb: 0.5, pl: 1 }} style={style}>
          <Grid item={true} style={{ flex: 1 }}>
            {file.imageUrl ? this.renderImageFile(file) : this.renderRegularFile(file)}
          </Grid>
          <Grid item={true} sx={{ pt: 1, pr: 1 }}>
            {file.state === 'WAITING' && <Upload style={{ color: COLORS.black[40] }} />}
            {file.state === 'UPLOADING' && <CircularProgress color="primary" size={24} thickness={7} />}
            {file.state === 'DONE' && <Upload style={{ color: COLORS.success[100] }} />}
          </Grid>
          <Grid item={true}>
            <IconButton onClick={() => this.removeFile(file)} color="primary" size="large" disabled={this.props.isNonEditable}>
              <Delete style={{ color: COLORS.white[50] }} />
            </IconButton>
          </Grid>
          {this.renderDownloadFile(file)}
        </Grid>
      );
    };

    return (
      <AutoSizer>
        {({ width, height }: { width: number; height: number }) => (
          <FixedSizeList width={width} height={height} itemCount={files.length} itemSize={47} outerElementType={OuterElement}>
            {renderFile}
          </FixedSizeList>
        )}
      </AutoSizer>
    );
  }

  private renderImageFile(file: UploadFile) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <Paper
            elevation={2}
            style={{
              display: 'inline-block',
              width: '50px',
              height: '25px',
              backgroundImage: `url(${file.imageUrl})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </div>
        <div>{file.path}</div>
      </div>
    );
  }

  private renderRegularFile(file: UploadFile) {
    return <span style={file.state === 'IGNORED' ? { color: 'grey' } : {}}>{file.path}</span>;
  }

  private removeFile(file: UploadFile) {
    // remove the file from both files and file upload queue
    for (const files of [this.files, this.filesToUpload, this.ignoredFiles]) {
      const idx = files.indexOf(file);
      if (idx >= 0) {
        files.splice(idx, 1);
      }
    }
    // cb only happens after all uploads are complete
    // we don't call it here as there will be some files in this.files that are not uploaded yet
    if (this.filesToUpload.length === 0) {
      this.notifyFilesModified(this.files);
    }
    this.forceUpdate();
  }

  private renderDownloadFile(file: UploadFile) {
    if (!this.props.getFileDownloadUrl || !file.uploadId) {
      return null;
    }
    const filePathSplits = file.path.split('/');
    const filename = filePathSplits[filePathSplits.length - 1] || '';
    return (
      <Grid item={true}>
        <Download getFileDownloadUrl={this.props.getFileDownloadUrl} uploadId={file.uploadId} filename={filename} />
      </Grid>
    );
  }

  private renderErrorDialog() {
    const handleClose = () => {
      this.setState({ errors: [] });
    };
    const { errors } = this.state;
    return (
      <Dialog open={errors.length > 0} onClose={handleClose}>
        <StyledHeaderText variant="h2" sx={{ textAlign: 'center' }}>
          Oops! Something went wrong.
        </StyledHeaderText>
        <DialogContent>
          <ul>
            {errors.map((error) => (
              <li key={error} style={errors.length === 1 ? { listStyleType: 'none' } : {}}>
                {error}
              </li>
            ))}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus={true}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private isBusy() {
    const { files } = this.state;
    return !files.every((file) => file.state === 'DONE' || file.state === 'IGNORED');
  }

  private renderRemoveAll() {
    if (this.props.maxFiles && this.props.maxFiles < 2) {
      return;
    }
    if (this.state.files.length < 1) {
      return;
    }
    return (
      <div style={{ position: 'absolute', right: 0, top: -38 }}>
        <StyledButton variant="link" disabled={this.isBusy() || this.props.isNonEditable} onClick={() => this.removeAllFiles()} color="red">
          Delete all files
        </StyledButton>
      </div>
    );
  }

  private removeAllFiles() {
    if (this.isBusy()) {
      return;
    }
    this.files = [];
    this.filesToUpload = [];
    this.ignoredFiles = [];
    this.uploadLoopId = 0;
    this.setState(this.initialState());
    this.notifyFilesModified(this.files);
  }

  private initialState(): UploadDropZoneState {
    return {
      files: this.files,
      ignoredFiles: [],
      errors: [],
    };
  }
}

export default withSession<UploadDropZoneProps>(UploadDropZone);
