export interface RevenueShareFormData {
  // confirmation of ownership
  doesNotViolate: boolean;

  suitableForChildren: boolean;
  // eligible for revenue share
  isEligible: boolean;
  // increase revenue share
  makeExclusive: boolean;
  allowDistribution: boolean;
  hasImplementedSDK: boolean;
  hasImplementedLogo: boolean;
  // opt out of embedding
  disallowEmbedding: boolean;
  isInvited: boolean;
  hasCustomContract: boolean;
}

export function buildDefaultRevenueShareFormData(): RevenueShareFormData {
  return {
    doesNotViolate: false,
    suitableForChildren: false,
    isEligible: false,
    makeExclusive: false,
    allowDistribution: false,
    hasImplementedLogo: false,
    hasImplementedSDK: false,
    disallowEmbedding: false,
    isInvited: false,
    hasCustomContract: false,
  };
}

export type RevenueShareFormProblems = 'TNC_NOT_ACCEPTED';

export function validateRevenueShareForm(form: RevenueShareFormData): RevenueShareFormProblems[] {
  const { doesNotViolate, suitableForChildren, isInvited, hasCustomContract } = form;
  if (isInvited) {
    if (!doesNotViolate && !hasCustomContract) {
      return ['TNC_NOT_ACCEPTED'];
    }
  } else if (doesNotViolate && suitableForChildren) {
    return [];
  }
  return ['TNC_NOT_ACCEPTED'];
}
