import React from 'react';
import { Link, useTheme } from '@mui/material';
import { GameEngineType, unityGameEnginesWithCompression } from '../../../../../../../common/domain/game';
import UnityCompressionSelect, { Unity56Encoding } from './UnityCompressionSelect';
import { UploadedFile } from '../../../../../../../common/domain/upload';
import { StyledBodyText } from '../../../../../../../common/Styleguide/Common/Text';
import { COLORS } from '../../../../../../../common/Styleguide/Common/colors';
import UnityCompressionWarnings from './UnityCompressionWarnings';

export interface UnityCompressionOptionProps {
  gameEngineType: GameEngineType | null;
  unity56Encoding?: Unity56Encoding;
  onChange: (newEncoding: Unity56Encoding) => void;
  files: UploadedFile[] | null;
  isNonEditable?: boolean;
}

const UnityCompressionOption: React.FC<UnityCompressionOptionProps> = (props) => {
  const theme = useTheme();
  const { gameEngineType, unity56Encoding, onChange, isNonEditable } = props;

  if (!gameEngineType || !unityGameEnginesWithCompression.includes(gameEngineType)) {
    return null;
  }

  const instructionsLink = 'https://docs.unity3d.com/Manual/webgl-deploying.html';

  return (
    <>
      <StyledBodyText variant="h3" sx={{ mt: 3, mb: 1.25 }}>
        Compression
      </StyledBodyText>
      {!isNonEditable && (
        <StyledBodyText sx={{ m: 0 }} color="white30">
          Select the compression you used when exporting your game. You can find more information{' '}
          <Link href={instructionsLink} target="_blank" style={{ color: COLORS.brand[100] }}>
            here
          </Link>
          .
        </StyledBodyText>
      )}
      <div style={{ paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
        <UnityCompressionSelect
          gameEngineType={gameEngineType}
          onChange={onChange}
          unity56Encoding={unity56Encoding}
          isNonEditable={isNonEditable}
        />
      </div>

      {!isNonEditable && <UnityCompressionWarnings gameEngineType={gameEngineType} files={props.files} unity56Encoding={unity56Encoding} />}
    </>
  );
};

export default UnityCompressionOption;
