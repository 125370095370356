import * as React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';

const Eye: React.FC<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 60 60">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 25C27.2386 25 25 27.2386 25 30C25 32.7614 27.2386 35 30 35C32.7614 35 35 32.7614 35 30C35 27.2386 32.7614 25 30 25ZM20 30C20 24.4772 24.4772 20 30 20C35.5228 20 40 24.4772 40 30C40 35.5228 35.5228 40 30 40C24.4772 40 20 35.5228 20 30Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4418 20.0998C13.664 23.0348 10.6405 26.578 8.84167 28.9259C8.34326 29.5765 8.34326 30.4235 8.84168 31.0741C10.6405 33.422 13.664 36.9652 17.4418 39.9002C21.2478 42.8572 25.5642 45 30 45C34.4358 45 38.7523 42.8572 42.5583 39.9002C46.336 36.9652 49.3596 33.422 51.1584 31.0741C51.6568 30.4235 51.6568 29.5765 51.1584 28.9259C49.3596 26.578 46.336 23.0348 42.5583 20.0998C38.7523 17.1428 34.4358 15 30 15C25.5642 15 21.2478 17.1428 17.4418 20.0998ZM14.3742 16.1514C18.5929 12.8737 23.9814 10 30 10C36.0187 10 41.4072 12.8737 45.6259 16.1514C49.8728 19.4509 53.1934 23.3606 55.1275 25.8852C57.0004 28.3299 57.0004 31.6701 55.1275 34.1148C53.1934 36.6394 49.8728 40.5491 45.6259 43.8486C41.4072 47.1263 36.0187 50 30 50C23.9814 50 18.5929 47.1263 14.3742 43.8486C10.1273 40.5491 6.80664 36.6394 4.87257 34.1148C2.99963 31.6701 2.99963 28.3299 4.87257 25.8852C6.80664 23.3606 10.1273 19.4509 14.3742 16.1514Z"
      />
    </SvgIcon>
  );
});

export default Eye;
