import * as React from 'react';
import { NumberContainer, PathItem, Root, StyledArrowSimpleRight } from './releasePath.styles';
import { ReleasePathContext } from './ReleasePathProvider';

const ReleasePath: React.FunctionComponent = () => {
  const { releasePathState } = React.useContext(ReleasePathContext);
  const isQaActive = ['submit', 'review', 'qa'].includes(releasePathState);
  const isSubmitActive = ['submit', 'review'].includes(releasePathState);
  const isReviewActive = ['review'].includes(releasePathState);
  return (
    <Root>
      <PathItem active>
        <NumberContainer active>1</NumberContainer>
        Details
      </PathItem>
      <StyledArrowSimpleRight />
      <PathItem active={isQaActive}>
        <NumberContainer active={isQaActive}>2</NumberContainer>
        QA tool
      </PathItem>
      <StyledArrowSimpleRight />
      <PathItem active={isSubmitActive}>
        <NumberContainer active={isSubmitActive}>3</NumberContainer>
        Submit
      </PathItem>
      <StyledArrowSimpleRight />
      <PathItem active={isReviewActive}>
        <NumberContainer active={isReviewActive}>4</NumberContainer>
        Sent for review
      </PathItem>
    </Root>
  );
};

export default ReleasePath;
