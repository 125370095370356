import { GameEngineType } from './domain/game';

export type GAME_OPTIONS_TYPE =
  | 'UNITY_2023'
  | 'UNITY_2022'
  | 'UNITY_2021'
  | 'UNITY_2020'
  | 'UNITY_5_4_X'
  | 'UNITY_5_6_X'
  | 'FLASH'
  | 'IFRAME'
  | 'LINK'
  | 'SWFVIEWER'
  | 'RUFFLE';

export const GAME_OPTIONS_TYPES: GAME_OPTIONS_TYPE[] = [
  'UNITY_2023',
  'UNITY_2022',
  'UNITY_2021',
  'UNITY_2020',
  'UNITY_5_6_X',
  'UNITY_5_4_X',
  'FLASH',
  'IFRAME',
  'LINK',
  'SWFVIEWER',
  'RUFFLE',
];

export type GAME_TECHNOLOGY = 'UNITY' | 'FLASH' | 'IFRAME' | 'LINK' | 'SWFVIEWER' | 'RUFFLE';
export const GAME_TECHNOLOGIES: GAME_TECHNOLOGY[] = ['IFRAME', 'UNITY', 'FLASH', 'LINK', 'SWFVIEWER', 'RUFFLE'];

/** These game engines will display the iOS/Android friendly toggles */
export const MOBILE_FRIENDLY_GAME_ENGINES: GameEngineType[] = ['IFRAME', 'HTML5', 'UNITY_2020', 'UNITY_2021', 'UNITY_2022', 'UNITY_2023'];

export type TechnologyGameOptions =
  | IframeGameOptions
  | UnityGameOptions
  | FlashGameOptions
  | LinkGameOptions
  | SWFViewerGameOptions
  | RuffleGameOptions;

export interface IframeGameOptions {
  loaderConfig: IframeLoaderConfig;
  iframeOptions: string[];
}

export type IframeLoaderConfig = {
  url: string | null;
};

export interface UnityGameOptions {
  loaderConfig: UnityLoaderConfig;
  version: UNITY_VERSION;
}

export interface SWFViewerGameOptions {
  loaderConfig: SWFViewerLoaderConfig;
}

export interface SWFViewerLoaderConfig {
  swfLocation: string;
}

export interface RuffleLoaderConfig {
  swfLocation: string;
  loaderLocation?: string;
}

export interface RuffleGameOptions {
  loaderConfig: RuffleLoaderConfig;
}

export type UnityLoaderConfig =
  | Unity54XLoaderConfig
  | Unity56XLoaderConfig
  | Unity2020LoaderConfig
  | Unity2021LoaderConfig
  | Unity2022LoaderConfig
  | Unity2023LoaderConfig;

export interface Unity2023GameOptions extends UnityGameOptions {
  version: 'unity_2023';
  loaderConfig: Unity2023LoaderConfig;
}

export interface Unity2022GameOptions extends UnityGameOptions {
  version: 'unity_2022';
  loaderConfig: Unity2022LoaderConfig;
}

export interface Unity2021GameOptions extends UnityGameOptions {
  version: 'unity_2021';
  loaderConfig: Unity2021LoaderConfig;
}

export interface Unity2020GameOptions extends UnityGameOptions {
  version: 'unity_2020';
  loaderConfig: Unity2020LoaderConfig;
}

export interface Unity56XGameOptions extends UnityGameOptions {
  version: '5_6_X';
  loaderConfig: Unity56XLoaderConfig;
}

export interface Unity54XGameOptions extends UnityGameOptions {
  version: '5_4_X';
  loaderConfig: Unity54XLoaderConfig;
}

export type UNITY_VERSION = '5_4_X' | '5_6_X' | 'unity_2020' | 'unity_2021' | 'unity_2022' | 'unity_2023';

export const UNITY_VERSIONS: UNITY_VERSION[] = ['5_4_X', '5_6_X', 'unity_2020', 'unity_2021', 'unity_2022', 'unity_2023'];

export interface Unity2020LoaderConfig {
  showProgress?: boolean;
  unityLoaderUrl: string;
  unityConfigOptions: {
    dataUrl: string;
    frameworkUrl: string;
    codeUrl: string;
    memoryUrl?: string;
    symbolsUrl?: string;
    streamingAssetsUrl?: string;
    devicePixelRatio?: number;
  };
  unitySaveFileNames?: string[];
}

export type Unity2021LoaderConfig = Unity2020LoaderConfig;

export type Unity2022LoaderConfig = Unity2020LoaderConfig;

export type Unity2023LoaderConfig = Unity2020LoaderConfig;

export interface Unity56XLoaderConfig {
  moduleJsonUrl: string;
  unityLoaderUrl: string;
  unitySaveFileNames?: string[];
}

export interface Unity54XLoaderConfig {
  module: {
    asmUrl?: string;
    memUrl: string;
    codeUrl: string;
    dataUrl: string;
  };
  unityLoaderUrl: string;
}

export interface FlashGameOptions {
  loaderConfig: FlashLoaderConfig;
}

export interface FlashLoaderConfig {
  flashVars: {};
  swfParams: SwfParamsLoaderConfig;
  swfLocation: string;
}

export interface SwfParamsLoaderConfig {
  bgcolor: string;
  quality: string;
  allowNetworking: string;
  allowScriptAccess: string;
}

export interface LinkGameOptions {
  loaderConfig: LinkLoaderConfig;
  url: string;
  image: string;
}

export interface LinkLoaderConfig extends IframeLoaderConfig {
  isDownloadable?: boolean;
}

export function getOptionsTypeFromTech(technology: GAME_TECHNOLOGY, rawOptions: string): GAME_OPTIONS_TYPE {
  if (technology === 'UNITY') {
    const parsedOptions = JSON.parse(rawOptions) as UnityGameOptions;
    const { version } = parsedOptions;
    if (!version) {
      throw new Error('[Game tech] version key missing in Unity game options');
    }
    if (version === '5_4_X') {
      return 'UNITY_5_4_X';
    } else if (version === '5_6_X') {
      return 'UNITY_5_6_X';
    } else if (version === 'unity_2020') {
      return 'UNITY_2020';
    } else if (version === 'unity_2021') {
      return 'UNITY_2021';
    } else if (version === 'unity_2022') {
      return 'UNITY_2022';
    } else if (version === 'unity_2023') {
      return 'UNITY_2023';
    } else {
      throw new Error('[Game tech] Unsupported Unity version');
    }
  } else {
    return technology;
  }
}

export function getTechFromOptionsType(optionsType: GAME_OPTIONS_TYPE): GAME_TECHNOLOGY {
  if (
    optionsType === 'UNITY_5_4_X' ||
    optionsType === 'UNITY_5_6_X' ||
    optionsType === 'UNITY_2020' ||
    optionsType === 'UNITY_2021' ||
    optionsType === 'UNITY_2022' ||
    optionsType === 'UNITY_2023'
  ) {
    return 'UNITY';
  } else {
    return optionsType;
  }
}

export function getDefaultFlashOptions(): FlashGameOptions {
  return {
    loaderConfig: {
      flashVars: {},
      swfParams: {
        bgcolor: '#fff',
        quality: 'high',
        allowNetworking: 'internal',
        allowScriptAccess: 'sameDomain',
      },
      swfLocation: '',
    },
  };
}

export function getDefaultSWFViewerOptions(): SWFViewerGameOptions {
  return {
    loaderConfig: {
      swfLocation: '',
    },
  };
}

export function getDefaultRuffleOptions(): RuffleGameOptions {
  return {
    loaderConfig: {
      swfLocation: '',
      loaderLocation: '',
    },
  };
}

export function getDefaultIframeOptions(): IframeGameOptions {
  return {
    loaderConfig: {
      url: '',
    },
    iframeOptions: [],
  };
}

export function getDefaultLinkOptions(): LinkGameOptions {
  return {
    loaderConfig: {
      url: '',
    },
    url: '',
    image: '',
  };
}

// also used for Unity2021, 2022 and 2023
function getUnity2020loaderOptions() {
  return {
    unityLoaderUrl: '',
    unityConfigOptions: {
      dataUrl: '',
      frameworkUrl: '',
      codeUrl: '',
      memoryUrl: '',
      symbolsUrl: '',
      streamingAssetsUrl: '',
    },
  };
}

export function getDefaultUnity_2020_Options(): Unity2020GameOptions {
  return {
    loaderConfig: getUnity2020loaderOptions(),
    version: 'unity_2020',
  };
}

export function getDefaultUnity_2021_Options(): Unity2021GameOptions {
  return {
    loaderConfig: getUnity2020loaderOptions(),
    version: 'unity_2021',
  };
}

export function getDefaultUnity_2022_Options(): Unity2022GameOptions {
  return {
    loaderConfig: getUnity2020loaderOptions(),
    version: 'unity_2022',
  };
}

export function getDefaultUnity_2023_Options(): Unity2023GameOptions {
  return {
    loaderConfig: getUnity2020loaderOptions(),
    version: 'unity_2023',
  };
}

export function getDefaultUnity_5_6_X_Options(): Unity56XGameOptions {
  return {
    loaderConfig: {
      moduleJsonUrl: '',
      unityLoaderUrl: '',
    },
    version: '5_6_X',
  };
}

export function getDefaultUnity_5_4_X_Options(): Unity54XGameOptions {
  return {
    loaderConfig: {
      module: {
        memUrl: '',
        codeUrl: '',
        dataUrl: '',
      },
      unityLoaderUrl: '',
    },
    version: '5_4_X',
  };
}

export function getDefaultTechnologyOptions(optionsType: GAME_OPTIONS_TYPE): TechnologyGameOptions {
  switch (optionsType) {
    case 'IFRAME':
      return getDefaultIframeOptions();
    case 'FLASH':
      return getDefaultFlashOptions();
    case 'UNITY_2023':
      return getDefaultUnity_2023_Options();
    case 'UNITY_2022':
      return getDefaultUnity_2022_Options();
    case 'UNITY_2021':
      return getDefaultUnity_2021_Options();
    case 'UNITY_2020':
      return getDefaultUnity_2020_Options();
    case 'UNITY_5_6_X':
      return getDefaultUnity_5_6_X_Options();
    case 'UNITY_5_4_X':
      return getDefaultUnity_5_4_X_Options();
    case 'LINK':
      return getDefaultLinkOptions();
    case 'SWFVIEWER':
      return getDefaultSWFViewerOptions();
    case 'RUFFLE':
      return getDefaultRuffleOptions();
    default:
      throw new Error(`[Tech index] unknown game options type: ${optionsType}`);
  }
}
