import React from 'react';
import { GAME_INFO_QUERY, GameInfoQuery } from './gameinfoform-graphql';
import GameInfoForm from './GameInfoForm';
import { useQuery } from '@apollo/client';
import GraphQLLoader from '../../../../../../common/graphql/graphql-loader';

export interface Props {}

/**
 * The game info form requires the list of tags and categories, which this loadeder will fetch.
 */
const GameInfoLoader: React.FC<Props> = () => {
  const { loading, error, data } = useQuery<GameInfoQuery>(GAME_INFO_QUERY);
  const tags = data?.tags.filter((tag) => tag.isCore) || [];

  return (
    <GraphQLLoader loading={loading} error={error}>
      {!loading && !error && <GameInfoForm categories={data!.categories} tags={tags} />}
    </GraphQLLoader>
  );
};

export default GameInfoLoader;
