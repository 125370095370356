import React from 'react';
import { gameEngineTypes, renderGameEngineType, GameEngineType } from '../../../../../../common/domain/game';
import { DOCS_URL } from '../../../../SideMenu/Menu';
import { StyledBodyText } from '../../../../../../common/Styleguide/Common/Text';
import { CustomSelect } from '../../../../../../common/Styleguide/Common/Select';
import { Option } from '@mui/base';
import { HALF_INPUT_WIDTH } from '../UpdateSubmission/UpdateSubmission.types';

function gameEngineTypeOptions() {
  return gameEngineTypes.map((key) => {
    return {
      label: renderGameEngineType(key),
      value: key,
    };
  });
}

interface TypeSelectProps {
  gameEngineType?: GameEngineType;
  disabled?: boolean;
  onGameEngineTypeChange: (newValue: GameEngineType) => void;
  excludedGameEngines?: GameEngineType[];
  error?: boolean;
}

export const TypeSelect: React.FunctionComponent<TypeSelectProps> = ({
  onGameEngineTypeChange,
  gameEngineType,
  excludedGameEngines,
  disabled,
  error = false,
}) => {
  return (
    <>
      <StyledBodyText variant="h3" sx={{ marginY: 1.25 }}>
        Game Type <span>*</span>
      </StyledBodyText>
      <CustomSelect
        id="gameEngineType"
        value={gameEngineType || ''}
        placeholder="Select"
        error={error}
        disabled={disabled}
        onChange={(_e: any, newValue: any) => {
          onGameEngineTypeChange(newValue);
        }}
        style={{ width: HALF_INPUT_WIDTH }}
      >
        {gameEngineTypeOptions().map((option) => {
          if (excludedGameEngines && excludedGameEngines.includes(option.value)) {
            return null;
          }
          return (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          );
        })}
      </CustomSelect>
      {gameEngineType?.includes('UNITY') && (
        <StyledBodyText color="white30" variant="bodyLower">
          These{' '}
          <a href={`${DOCS_URL}/sdk/unity/resources/export-tips/`} target="_blank" rel="noreferrer">
            Unity WebGL export tips
          </a>{' '}
          may help you optimize your game, thus increasing your revenue.
        </StyledBodyText>
      )}
    </>
  );
};

export default TypeSelect;
