import * as React from 'react';
import documentUrl from '../document-url';

export const privacyVersion = 'privacy_policy_developer_portal_20180928';

export const privacyUrl = documentUrl(`${privacyVersion}.pdf`);

interface PrivacyLinkProps {
  style?: React.CSSProperties;
}

const PrivacyLink: React.FC<PrivacyLinkProps> = ({ style }) => (
  <a style={style} href={privacyUrl} target="_blank" rel="noopener noreferrer">
    Privacy Policy
  </a>
);

export default PrivacyLink;
