import React from 'react';
import { StandalonePreviewUrlProvider } from './PreviewUrlProvider';
import QATool from '../UserDeveloper/QATool/QATool';
import { GraphGameEngineType } from '../../../../common/domain/game';
import { PreviewOptions } from './PreviewRoute';

interface StandalonePreviewLoaderInput {
  standaloneId: string;
  engine: GraphGameEngineType;
  previewOptions: PreviewOptions;
}
const StandalonePreviewLoader: React.FC<StandalonePreviewLoaderInput> = ({ standaloneId, engine, previewOptions }) => {
  return (
    <StandalonePreviewUrlProvider
      standaloneId={standaloneId}
      engine={engine}
      minTimeForAds={previewOptions.minTimeForAds}
      noTestAds={previewOptions.noTestAds}
      previewComponent={(url) => <QATool gameName={'Your Game'} previewUrl={url} previewOptions={previewOptions} />}
    />
  );
};

export default StandalonePreviewLoader;
