import React from 'react';
import { styled } from '@mui/material/styles';
import { Divider, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Prompt } from 'react-router';
import { DeveloperSubmissionUpdateInput, updateSubmission } from '../Mutations/update-submission-mutation';
import { ApolloError } from '@apollo/client';
import { GraphQLError } from '../../../../../../common/components/ApolloError/GraphQLError';
import { convertGameCoversToInput, uploadedFileToInput } from '../../../../../../common/graphql/upload-input';
import { UploadedFile } from '../../../../../../common/domain/upload';
import { DeveloperSubmissionSubmitForApprovalInput, submissionSubmitForApproval } from '../Mutations/update-submission-revenue-mutation';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { generateSubmissionPreviewRoute, QANextStep } from '../../../Preview/PreviewRoute';
import withSubmission, { WithSubmission } from '../Context/withSubmission';
import { revenueShareFormToInput, submissionToGraphQLGame } from '../../SubmitGame/submission-helpers';
import { updateReleasedSubmission } from '../Mutations/update-released-submission-mutation';
import styledUpdateSubmission, { classes } from './styledUpdateSubmission';
import { toast } from 'react-toastify';
import {
  GameEngineType,
  convertGameEngineToGraph,
  unityGameEnginesWithCompression,
  enginesWithSaveDataToggles,
} from '../../../../../../common/domain/game';
import UpdateSubmissionIframeNotice from './UpdateSubmissionIframeNotice';
import { StyledButton } from '../../../../../../common/Styleguide/Common/Button';
import { StyledBodyText } from '../../../../../../common/Styleguide/Common/Text';
import Alert from '../../../../../../common/Styleguide/Common/Alert';
import ActionLink, { ACTION_LINK_HEIGHT } from '../../../../Footer/ActionLink';
import { ReleasePathState, WithReleasePath, withReleasePath } from '../ReleasePath/ReleasePathProvider';
import moment from 'moment';
import Check from '../../../../../../common/icons/Check';
import RevenueShareForm from '../RevenueShareForm/RevenueShareForm';
import { REAPPROVAL_ALLOWED_STATUSES, ALERT_TEXT, GAME_GUIDELINES_URL, NON_RELEASED_STATUSES } from './UpdateSubmission.types';
import GameFilesForm from '../GameFilesForm/GameFilesForm';
import GameInfoFormLoader from '../GameInfoForm/GameInfoFormLoader';

const Root = styled('div')(styledUpdateSubmission);

export type UpdateSubmissionStep = 'initial' | 'revenueShare';
export type SubmitState = 'not-started' | 'in-progress' | 'complete';

export type _UpdateSubmissionProps = UpdateSubmissionProps & RouteComponentProps & WithSubmission & WithReleasePath;

export interface UpdateSubmissionState {
  error?: ApolloError;
  isSaving: boolean;
  activeStep: UpdateSubmissionStep;
  filesChanged?: boolean;
  coverChanged?: boolean;
  iframeLinkChanged?: boolean;
  confirmationDialogOpened: boolean;
}

interface UpdateSubmissionProps {
  navToFeedback: () => void;
  onChangedStep: (newStep: UpdateSubmissionStep) => void;
}

class UpdateSubmission extends React.Component<_UpdateSubmissionProps, UpdateSubmissionState> {
  constructor(props: _UpdateSubmissionProps) {
    super(props);
    const { submission } = this.props;
    const paramsFromUrl = new URLSearchParams(this.props.location.search);
    const fromQA = submission && paramsFromUrl.get('fromQA') === 'true' && submission.qaResult;

    const activeStep = submission && submission.status === 'DRAFT' && fromQA ? 'revenueShare' : 'initial';
    this.props.onChangedStep(activeStep);
    this.state = {
      activeStep: activeStep,
      isSaving: false,
      filesChanged: submission && submission.filesChanged,
      coverChanged: submission && submission.coverChanged,
      iframeLinkChanged: submission && submission.iframeLinkChanged,
      confirmationDialogOpened: false,
    };
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onBeforeUnload);
    this.props.setReleasePathState(this.getReleasePathState());
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onBeforeUnload);
  }

  onBeforeUnload = (event: BeforeUnloadEvent) => {
    const { modified } = this.props;
    if (modified) {
      event.preventDefault();
      event.returnValue = ALERT_TEXT;
    }
  };

  render() {
    const { activeStep, confirmationDialogOpened } = this.state;
    const { revenueShareForm, submission } = this.props;
    const { doesNotViolate, suitableForChildren, isInvited, hasCustomContract } = revenueShareForm!;

    const allowedToSubmit = suitableForChildren && ((isInvited && (hasCustomContract || doesNotViolate)) || doesNotViolate);

    if (activeStep === 'revenueShare') {
      return (
        <>
          <Root sx={{ pb: `${ACTION_LINK_HEIGHT}px`, pl: 1.5 }}>
            {this.renderPrompt()}
            <RevenueShareForm />
            <Divider className={classes.divider} />
            <ActionLink
              primaryButton={{
                title: 'Submit for approval',
                callback: () => {
                  this.submitForApproval();
                },
              }}
              primaryButtonDisabled={!allowedToSubmit}
              secondaryButton={{
                title: 'Back to draft',
                callback: () => {
                  this.handleApprovalBack();
                  this.props.setReleasePathState('details');
                },
              }}
            />
            {confirmationDialogOpened && this.renderConfirmationDialogComplete()}
            {this.renderError()}
          </Root>
        </>
      );
    }

    return (
      <>
        <Root sx={{ pb: `${ACTION_LINK_HEIGHT}px` }}>
          {this.displayWarningIfNeeded()}
          {this.renderPrompt()}
          <Root>
            <GameInfoFormLoader />
            <GameFilesForm />
          </Root>
          {this.renderIframeNotify()}
          <Divider className={classes.divider} />
          {submission?.status !== 'REJECTED' && this.renderActionLinks()}
          {confirmationDialogOpened && this.renderConfirmationDialogComplete()}
          {this.renderError()}
        </Root>
      </>
    );
  }

  private canSubmitForReapproval() {
    const { submission } = this.props;
    const { filesChanged, coverChanged, iframeLinkChanged } = this.state;
    return (
      submission &&
      ((submission.gameEngineType === 'IFRAME' && submission.status === 'AWAITING_DEVELOPER_CHANGE') ||
        (REAPPROVAL_ALLOWED_STATUSES.includes(submission.status) && (coverChanged || filesChanged || iframeLinkChanged)))
    );
  }

  private showMissingFieldsToast() {
    toast.error('Please check if all the mandatory fields marked with * are filled before continuing', { autoClose: 8000 });
  }

  private renderActionLinks() {
    if (this.props.modified) {
      let label = 'Update';
      if (this.qaAfterUpdate()) {
        label = 'Update and go to QA';
      }

      return (
        <ActionLink
          primaryButton={{
            title: label,
            callback: !this.readyToSubmit() ? this.showMissingFieldsToast : this.handleUpdate,
          }}
          primaryButtonDisabled={this.state.isSaving}
        />
      );
    }

    if (this.canSubmitForReapproval()) {
      return (
        <ActionLink
          primaryButton={{
            title: 'Go to QA for Re-Approval',
            callback: this.submitForUpdateApproval,
          }}
        />
      );
    }
    const actionLinksData = this.getDefaultActionLinksData();
    if (!actionLinksData) {
      return null;
    }
    return <ActionLink primaryButton={actionLinksData.primaryButton} secondaryButton={actionLinksData.secondaryButton} />;
  }

  private getDefaultActionLinksData() {
    const { submission } = this.props;

    if (!!submission && this.isDraft()) {
      if (!submission.qaResult) {
        return {
          primaryButton: {
            title: 'Continue to QA tool',
            callback: this.canGoToQA()
              ? () => {
                  this.goToQA(QANextStep.REV_FORM);
                }
              : this.showMissingFieldsToast,
          },
          secondaryButton: undefined,
        };
      }
      return {
        primaryButton: {
          title: 'Continue to last step',
          callback: () => {
            this.setState({ activeStep: 'revenueShare' });
            this.props.onChangedStep('revenueShare');
            this.props.setReleasePathState('submit');
          },
        },
        secondaryButton: undefined,
      };
    }
    return null;
  }

  private getReleasePathState(): ReleasePathState {
    const { activeStep } = this.state;
    if (this.getHasBeenSubmittedForApproval()) {
      return 'review';
    }
    if (activeStep === 'revenueShare') {
      return 'submit';
    }
    return 'details';
  }

  private displayWarningIfNeeded() {
    const { submission } = this.props;
    const { activeStep } = this.state;

    if (this.getHasBeenSubmittedForApproval()) {
      return this.renderWarningWaitingForReview();
    }
    if (activeStep === 'revenueShare') {
      return this.renderWarningCongratulations();
    }
    if (submission?.status === 'AWAITING_DEVELOPER_CHANGE') {
      return this.renderWarningFeedback();
    }
    if (submission?.status === 'REJECTED') {
      return this.renderWarningRejected();
    }
    if (submission?.status === 'RELEASED') {
      return this.renderWarningCongratulations();
    }
  }

  private getHasBeenSubmittedForApproval() {
    const { submission } = this.props;
    return submission && (submission.status === 'NEW_SUBMISSION' || submission.status === 'SYNC_REQUIRED');
  }

  private renderWarningWaitingForReview() {
    return (
      <Alert color="purple" title="Waiting for review">
        Your game has been sent for review. We will notify you once the team has reviewed your game.
      </Alert>
    );
  }

  private renderWarningCongratulations() {
    return (
      <Alert color="green" title="Congratulations!">
        The team has reviewed your game, and approved it! It is now live on CrazyGames.
      </Alert>
    );
  }

  private renderWarningRejected() {
    return (
      <Alert color="red" title="Your submission has been rejected">
        Unfortunately, your game has been rejected. Make sure to read{' '}
        <a target="_blank" rel="noopener noreferrer" href={GAME_GUIDELINES_URL}>
          our documentation
        </a>{' '}
        to find all the guidelines and requirements regarding game submissions. Due to the large number of submissions we receive every day,
        we are unable to give you personalised feedback. Thank you for your understanding.
      </Alert>
    );
  }

  private renderWarningFeedback() {
    const feedback = this.props.submission?.feedback;
    if (!feedback || feedback?.length < 1) {
      return null;
    }
    const lastFeedback = feedback.at(-1);
    const lastFeedbackDate = moment(lastFeedback?.postedOn).format('DD.MM.YYYY');
    const title = (
      <>
        Feedback from {lastFeedbackDate}{' '}
        <StyledButton variant="link" onClick={this.props.navToFeedback}>
          View all team feedback
        </StyledButton>{' '}
      </>
    );
    return (
      <Alert color="orange" title={title}>
        Here's the feedback from our team:
        <br />
        <p>{lastFeedback?.comment && <em dangerouslySetInnerHTML={{ __html: lastFeedback?.comment.replaceAll('\n', '<br />') }} />}</p>
        Once you’ve applied those changes, please upload the files and go to our QA tool to submit again.
      </Alert>
    );
  }

  private isDraft() {
    const { submission } = this.props;
    return submission && submission.status === 'DRAFT';
  }

  private isReleased() {
    const { submission } = this.props;
    return submission && !NON_RELEASED_STATUSES.includes(submission.status);
  }

  private renderPrompt() {
    return <Prompt when={this.props.modified} message={ALERT_TEXT} />;
  }

  private renderConfirmationDialogComplete() {
    return (
      <Dialog open={this.state.confirmationDialogOpened} onClose={this.reloadWindow}>
        <DialogTitle id="alert-dialog-title" sx={{ pb: 0, mt: 1 }}>
          <Check color="success" style={{ width: 60, height: 60 }} />
          <br />
          Game submitted
        </DialogTitle>
        <DialogContent>
          <StyledBodyText id="alert-dialog-description" color="white60" sx={{ textAlign: 'center', px: 2 }}>
            Your game has been sent for review. We will notify you once the team has reviewed your game. You are still allowed to update
            your submission during that time.
          </StyledBodyText>
        </DialogContent>
        <DialogActions sx={{ py: 3 }}>
          <StyledButton onClick={this.reloadWindow} variant="contained" color="purple" height={50} sx={{ minWidth: 200 }}>
            Back to my game
          </StyledButton>
        </DialogActions>
      </Dialog>
    );
  }

  private reloadWindow() {
    window.location.reload();
  }

  private renderIframeNotify = () => {
    const { submission } = this.props;

    return (
      submission &&
      submission.gameEngineType === 'IFRAME' &&
      this.isReleased() &&
      !this.props.isNonEditable && <UpdateSubmissionIframeNotice submission={submission} />
    );
  };

  private handleApprovalBack = () => {
    this.setState({ activeStep: 'initial' });
    this.props.onChangedStep('initial');
  };

  private renderError() {
    const error = this.state.error;
    return <GraphQLError error={error} />;
  }

  private qaAfterUpdate() {
    const { submission, revalidateByQA } = this.props;
    return revalidateByQA && submission && !REAPPROVAL_ALLOWED_STATUSES.includes(submission.status);
  }

  private goToQA(nextStep: QANextStep) {
    const { submission } = this.props;
    if (submission) {
      const preview = generateSubmissionPreviewRoute(submission.id, {
        QANextStep: nextStep,
        draft: submission.status === 'DRAFT',
      });
      this.props.history.push({
        pathname: preview.pathname,
        search: preview.query,
      });
    }
  }

  private readyToSubmit(): boolean {
    const { infoFormProblems, fileFormProblems, revenueShareFormValid } = this.props;
    const { activeStep } = this.state;
    const infoFormValid = !infoFormProblems || infoFormProblems.length === 0;
    const noFileErrors = !fileFormProblems || fileFormProblems.length === 0;

    // if submission is not an iframe, check if it has some files uploaded also
    const hasUploadedFiles =
      this.props.submission?.gameEngineType === 'IFRAME' || (this.props.filesForm?.files && this.props.filesForm?.files?.length > 0);
    const filesFormValid = noFileErrors && hasUploadedFiles;
    return (
      (activeStep === 'revenueShare' && infoFormValid && filesFormValid && revenueShareFormValid) || (infoFormValid && filesFormValid)!
    );
  }

  private canGoToQA() {
    const { infoFormProblems, fileFormProblems } = this.props;
    const { activeStep } = this.state;
    const infoFormValid = !infoFormProblems || infoFormProblems.length === 0;
    const noFileErrors = !fileFormProblems || fileFormProblems.length === 0;
    return activeStep !== 'initial' || (infoFormValid && noFileErrors);
  }

  private canBeSubmittedForApproval() {
    const { activeStep } = this.state;
    const { modified } = this.props;
    return activeStep === 'revenueShare' && this.isDraft() && !modified;
  }

  private submitForApproval = async () => {
    const { submission, revenueShareForm } = this.props;
    if (submission?.status === 'REJECTED') {
      return;
    }
    if (this.canBeSubmittedForApproval()) {
      this.setState({
        error: undefined,
      });

      try {
        const input = {
          id: submission!.id,
          revenueShareInputV2: revenueShareFormToInput(revenueShareForm!),
        } as DeveloperSubmissionSubmitForApprovalInput;
        await submissionSubmitForApproval(input);
        toast.success('Draft successfully updated.');
        this.setState({ activeStep: 'initial', confirmationDialogOpened: true });
        this.props.onChangedStep('initial');
      } catch (err) {
        this.setState({
          error: err as ApolloError,
        });
        toast.error('Error updating your game.');
      }
    }
  };

  private submitForUpdateApproval = () => {
    const { submission } = this.props;
    if (submission?.status === 'REJECTED') {
      return;
    }
    this.setState({
      error: undefined,
    });
    if (submission!.status === 'RELEASED' || submission!.status === 'SYNC_REQUIRED') {
      return this.goToQA(QANextStep.SYNC);
    }
    if (submission!.status === 'AWAITING_DEVELOPER_CHANGE') {
      return this.goToQA(QANextStep.REAPPROVAL);
    }
    return this.setState({
      error: new ApolloError({
        errorMessage: 'Status not valid for re-approval',
      }),
    });
  };

  private handleUpdate = async () => {
    const { filesForm, infoForm, revenueShareForm, submission } = this.props;
    const { activeStep } = this.state;
    if (submission?.status === 'REJECTED') {
      return;
    }
    if (this.readyToSubmit()) {
      this.setState({
        error: undefined,
        isSaving: true,
      });
      try {
        let updatedSubmission;
        if (this.isReleased()) {
          const hasAPSSupport = enginesWithSaveDataToggles.includes(submission!.gameEngineType);
          const response = await toast.promise(
            updateReleasedSubmission({
              id: submission!.id,
              gameFiles: (filesForm!.files as UploadedFile[]).map((gf) => uploadedFileToInput(gf)),
              gameCovers: convertGameCoversToInput(filesForm!.gameCovers),
              iframeLink: filesForm!.iframeLink || undefined,
              unity56Encoding: unityGameEnginesWithCompression.includes(submission!.gameEngineType)
                ? filesForm!.unity56Encoding
                : undefined,
              // front end is now handling a single file name, while backend is storing them as an array
              unitySaveFileNames: filesForm!.unitySaveFileName ? [filesForm!.unitySaveFileName] : undefined,
              gameEngineType: filesForm!.gameEngineType ? convertGameEngineToGraph(filesForm!.gameEngineType as GameEngineType) : undefined,
              apsDetail: hasAPSSupport
                ? {
                    progressType: filesForm!.progressSaveType || 'UNKNOWN',
                  }
                : {},
            }),
            {
              pending: 'Updating submission...',
              success: 'Submission Updated!',
              error: 'Error updating submission',
            },
          );

          updatedSubmission = response.data!.developerReleasedSubmissionUpdate;
        } else {
          const revenueShareFormData = activeStep === 'revenueShare' ? revenueShareForm : undefined;
          const input = submissionToGraphQLGame(false, infoForm!, filesForm!, revenueShareFormData);
          const updateInput: DeveloperSubmissionUpdateInput = { ...input, id: submission!.id };
          const response = await toast.promise(updateSubmission(updateInput), {
            pending: 'Updating submission...',
            success: 'Submission Updated!',
            error: 'Error updating submission',
          });

          updatedSubmission = response.data!.developerSubmissionUpdate;
        }

        if (this.qaAfterUpdate()) {
          this.props.setIsModified(false, () => {
            // do this in callback to not get "Are you sure you want to leave?" popup
            this.goToQA(QANextStep.UPDATE_COMPLETE);
          });
        } else {
          this.props.setIsModified(false);
          this.setState({
            isSaving: false,
            filesChanged: updatedSubmission.filesChanged,
            coverChanged: updatedSubmission.coverChanged,
            iframeLinkChanged: updatedSubmission.iframeLinkChanged,
          });
        }
      } catch (err) {
        this.setState({
          error: err as ApolloError,
          isSaving: false,
        });
      }
    }
  };
}

export default withReleasePath(withSubmission(withRouter(UpdateSubmission)));
